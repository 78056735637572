
export const GET_ORDER = 'GET_ORDER'
export const GET_ORDER_START = 'GET_ORDER_START'
export const GET_ORDER_FAIL = 'GET_ORDER_FAIL'
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS'


export const GET_ORDER_POSSIBLETOPAY = 'GET_ORDER_POSSIBLETOPAY'
export const GET_ORDER_POSSIBLETOPAY_START = 'GET_ORDER_POSSIBLETOPAY_START'
export const GET_ORDER_POSSIBLETOPAY_FAIL = 'GET_ORDER_POSSIBLETOPAY_FAIL'
export const GET_ORDER_POSSIBLETOPAY_SUCCESS = 'GET_ORDER_POSSIBLETOPAY_SUCCESS'

export const GET_ORDER_CUSTOMERADDRESS= 'GET_ORDER_CUSTOMERADDRESS'
export const GET_ORDER_CUSTOMERADDRESS_START = 'GET_ORDER_CUSTOMERADDRESS_START'
export const GET_ORDER_CUSTOMERADDRESS_FAIL = 'GET_ORDER_CUSTOMERADDRESS_FAIL'
export const GET_ORDER_CUSTOMERADDRESS_SUCCESS = 'GET_ORDER_CUSTOMERADDRESS_SUCCESS'


export const DELETE_ORDER = 'DELETE_ORDER'
export const DELETE_ORDER_START = 'DELETE_ORDER_START'
export const DELETE_ORDER_FAIL = 'DELETE_ORDER_FAIL'
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS'

export const POST_ORDER = 'POST_ORDER'
export const POST_ORDER_START = 'POST_ORDER_START'
export const POST_ORDER_FAIL = 'POST_ORDER_FAIL'
export const POST_ORDER_SUCCESS = 'POST_ORDER_SUCCESS'

export const PUT_ORDER = 'PUT_ORDER'
export const PUT_ORDER_START = 'PUT_ORDER_START'
export const PUT_ORDER_FAIL = 'PUT_ORDER_FAIL'
export const PUT_ORDER_SUCCESS = 'PUT_ORDER_SUCCESS'