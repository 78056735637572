import * as actionTypes from "../../actions/location/actionTypes";

import { loadState, saveState } from "../../../localStorage";
const initialState = {
  location: "",
};
const reducer = (
  location = loadState("location") === undefined
    ? initialState
    : loadState("location"),
  action
) => {
  switch (action.type) {
    case actionTypes.ADD_LOCATION:
      let tempAddress = action.location;

      saveState("location", tempAddress);

      return {
        location: tempAddress,
      };
    /////////////////////
    case actionTypes.GET_LOCATION:
      let address = loadState("location");

      return {
        location: address,
      };
    ////////////////////
    default:
      return location;
  }
};
export default reducer;
