import * as actionTypes from '../../actions/products/actionTypes'
import produce from 'immer'

const initialState = {
    products: null,
    error: null,
    loading: false,

}

const reducer = (state = initialState, action) => 
produce(state, draft => {
    switch(action.type) {

        case actionTypes.GET_PRODUCTS_START:
            draft.loading = true;
            draft.error = null;
            draft.editing = false;

            break;
        case actionTypes.GET_PRODUCTS_FAIL:
            draft.loading = false;
            draft.error = action.error;
            break;
        case actionTypes.GET_PRODUCTS_SUCCESS:
            draft.loading = false;
            draft.products = action.products
            break;
        default:
 
       
      
    }
})


 
export default reducer;