

export const GET_OPENING_HOURS = 'GET_OPENING_HOURS'
export const GET_OPENING_HOURS_START = 'GET_OPENING_HOURS_START'
export const GET_OPENING_HOURS_FAIL = 'GET_OPENING_HOURS_FAIL'
export const GET_OPENING_HOURS_SUCCESS = 'GET_OPENING_HOURS_SUCCESS'

export const DELETE_OPENING_HOURS = 'DELETE_OPENING_HOURS'
export const DELETE_OPENING_HOURS_START = 'DELETE_OPENING_HOURS_START'
export const DELETE_OPENING_HOURS_FAIL = 'DELETE_OPENING_HOURS_FAIL'
export const DELETE_OPENING_HOURS_SUCCESS = 'DELETE_OPENING_HOURS_SUCCESS'

export const POST_OPENING_HOURS = 'POST_OPENING_HOURS'
export const POST_OPENING_HOURS_START = 'POST_OPENING_HOURS_START'
export const POST_OPENING_HOURS_FAIL = 'POST_OPENING_HOURS_FAIL'
export const POST_OPENING_HOURS_SUCCESS = 'POST_OPENING_HOURS_SUCCESS'

export const PUT_OPENING_HOURS = 'PUT_OPENING_HOURS'
export const PUT_OPENING_HOURS_START = 'PUT_OPENING_HOURS_START'
export const PUT_OPENING_HOURS_FAIL = 'PUT_OPENING_HOURS_FAIL'
export const PUT_OPENING_HOURS_SUCCESS = 'PUT_OPENING_HOURS_SUCCESS'