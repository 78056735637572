import * as actionTypes from "../../actions/cart/actionTypes";

import { loadState, saveState } from "../../../localStorage";
const initialState = {
  addedItems: [],
  total: 0,
  restaurantID: 0,
  restaurantName: "",
};
const reducer = (
  state = loadState("state") === undefined ? initialState : loadState("state"),
  action
) => {
  switch (action.type) {
    case actionTypes.ADD_TO_CART:
      let addedItem = action;

      let existed_item = state.addedItems.find((item) => action.id === item.id);

      if (existed_item) {
        existed_item.quantity += 1;
        let updatedTotal = { ...state, total: state.total + addedItem.price };
        saveState("state", updatedTotal);
        return {
          ...state,

          total: state.total + addedItem.price,
        };
      } else {
        if (addedItem.restaurantID !== state.restaurantID) {
          state = initialState;
          localStorage.clear("state");
        }
        addedItem.quantity = 1;
        let newTotal = state.total + addedItem.price;
        let temp = {
          ...state,
          addedItems: [...state.addedItems, addedItem],
          total: newTotal,
          restaurantID: addedItem.restaurantID,
          restaurantName: addedItem.restaurantName,
        };
        saveState("state", temp);

        return {
          ...state,
          addedItems: [...state.addedItems, addedItem],
          total: newTotal,
          restaurantID: addedItem.restaurantID,
          restaurantName: addedItem.restaurantName,
        };
      }

    case actionTypes.REMOVE_FROM_CART:
      let itemToRemove = state.addedItems.find((item) => action.id === item.id);
      let new_items = state.addedItems.filter((item) => action.id !== item.id);
      //calculating the total
      let newTotalval =
        state.total - itemToRemove.price * itemToRemove.quantity;
      let tempRemove = { ...state, addedItems: new_items, total: newTotalval };
      saveState("state", tempRemove);
      return {
        ...state,
        addedItems: new_items,
        total: newTotalval,
      };

    case actionTypes.ADD_QUANTITY:
      let producsAdd = [...state.addedItems];
      let productAdd = producsAdd.find((item) => item.id === action.id);
      productAdd.quantity += 1;

      let newTotal = state.total + productAdd.price;

      let tempAdd = { ...state, total: newTotal };
      saveState("state", tempAdd);

      return {
        ...state,
        total: newTotal,
      };

    case actionTypes.SUB_QUANTITY:
      let productSub = state.addedItems.find((item) => item.id === action.id);

      //if the qt == 0 then it should be removed
      if (productSub.quantity === 1) {
        let new_items = state.addedItems.filter(
          (item) => item.id !== action.id
        );
        let newTotal = state.total - productSub.price;
        let tempSub = { ...state, addedItems: new_items, total: newTotal };
        saveState("state", tempSub);
        return {
          ...state,
          addedItems: new_items,
          total: newTotal,
        };
      } else {
        productSub.quantity -= 1;
        let newTotal = state.total - productSub.price;
        let tempSub = { ...state, total: newTotal };
        saveState("state", tempSub);
        return {
          ...state,
          total: newTotal,
        };
      }
    ////////////////
    case actionTypes.EMPTY_CART:
      return {
        ...state,
        products: state.products.map((product) =>
          product.selected
            ? { ...product, selected: false, quantity: 1 }
            : product
        ),
      };
    /////////////////////
    case actionTypes.UPDATE_NOTE:
      let note = state.addedItems.find((item) => item.id === Number(action.id));

      note.note = action.value;
      let tempNote = { ...state };
      saveState("state", tempNote);
      return {
        ...state,
      };
    /////////////////////
    case actionTypes.ORDER_ITEMS:
      let tempOrderItems = [];
      state.addedItems.map((item) => {
        tempOrderItems.push({
          productID: item.id,
          quantity: item.quantity,
          note: item.note,
        });
      });
      let tempOrders = {
        orderItems: tempOrderItems,
      };

      return {
        ...state,
        orderItems: tempOrderItems,
      };
    ////////////////////
    default:
      return state;
  }
};
export default reducer;
