import * as actionTypes from './actionTypes';
import axios from '../../../axios/localhost';

// Check current authentication state
export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            // dispatch(logout());
            // alert("Your session expired!");
        }, expirationTime * 10000);
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
           
        }
        else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            const userID = localStorage.getItem('userID');
            const token = localStorage.getItem('token');           
            const roles = JSON.parse(localStorage.getItem('roles'))


            if (expirationDate > new Date()){
                dispatch(loginSuccess({
                    userID: userID,
                    token: token,
                    roles: roles,


                }));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime())/1000));
            }
            else{
                dispatch(logout())
            }
        }
    }
}


// Login
export const loginStart = () => {
    return {
        type: actionTypes.LOGIN_START
    }
}

export const loginSuccess = (loginData) => {

    return {
        type: actionTypes.LOGIN_SUCCESS,
        userID: loginData.userID,
        roles: loginData.roles,
        token: loginData.token,

    }
}

export const loginFail = (error) => {
    return {
        type: actionTypes.LOGIN_FAIL,
        error: error
    }
}

export const login = (values) => {
    return dispatch => {
        dispatch(loginStart())


        let loginData = {
            email: values.email,
            password: values.password,
        }
       
        axios.post('/user/login', loginData)
            .then(response => {
               
                const expirationDate = new Date(new Date().getTime() + response.data.duration * 10000);

                localStorage.setItem('token', response.data.token);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userID', response.data.userID);
                localStorage.setItem('roles', JSON.stringify(response.data.roles))


                dispatch(loginSuccess({
                    ...response.data
                }));
                dispatch(checkAuthTimeout(response.data.duration));
            })
            .catch(error => {

                dispatch(loginFail(error.response.data.message));
            })
    }

}


// Registration
export const registerStart = () => {
    return {
        type: actionTypes.REGISTER_START
    }
}

export const registerSuccess = (message) => {
   
    return {
        type: actionTypes.REGISTER_SUCCESS,
        message: message
    }
}

export const registerFail = (message) => {
   
    return {
        type: actionTypes.REGISTER_FAIL,
        message: message
    }
}

export const register = ( values) => {
    
    return dispatch => {
        dispatch(registerStart());

        let registerData = {
            email: values.email,
            password: values.password,
        }

        let url = '/user/register';
        
        axios.post(url, registerData)
            .then(response => {
                dispatch(registerSuccess(response.data.message));
            })
            .catch(error => {
                dispatch(registerFail(error.response.data.message));
            });
    }
}


// Logout
export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userID');
    localStorage.removeItem('roles');
    

    return {
        type: actionTypes.AUTH_LOGOUT
    }
}










