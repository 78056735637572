import React, { useState, useEffect, Fragment } from "react";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Container from "@material-ui/core/Container";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import OutdoorGrillIcon from "@material-ui/icons/OutdoorGrill";
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Divider from "@material-ui/core/Divider";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StarIcon from "@material-ui/icons/Star";
import StepConnector from "@material-ui/core/StepConnector";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";

import RoomServiceIcon from "@material-ui/icons/RoomService";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Logo from "../../../../../assets/img/vegafoodies-3.png";
import smallLogo from "../../../../../assets/img/smallLogo.png";
import css from "./SuccessOrder.module.scss";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: "#ffbd2f",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#ffbd2f",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: "#ffbd2f",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: "#ffbd2f",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <AssignmentTurnedInIcon />,
    3: <RestaurantMenuIcon />,
    2: <OutdoorGrillIcon />,

    4: <RoomServiceIcon />,
    5: <DirectionsBikeIcon />,
    6: <StarIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Order Placed",
    "Preparation",
    "In The Oven",
    "Ready in Store",
    "Deliver Order",
    "Rate Your Order",
  ];
}

let SuccessOrder = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(1);
  const steps = getSteps();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <div className={css.headerWrapper}>
      <Container>
        <Box pt={3}>
          <Typography variant="h5" component="h5" gutterBottom>
            <img src={smallLogo}></img>Track Your Order
          </Typography>
        </Box>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box pt={3}>
          <Typography variant="h5" component="h5" gutterBottom>
            Details Of Your Order
          </Typography>
        </Box>
        <Paper elevation={0}>
          <Box p={2}>
            <Grid container spacing={4}>
              <Grid item sm={3} xs={12}>
                <Typography variant="h6" component="h6" gutterBottom>
                  Store
                </Typography>
                <Typography variant="body2" component="body2" gutterBottom>
                  Eindhoven
                </Typography>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Typography variant="h6" component="h6" gutterBottom>
                  Deliver To
                </Typography>
                <Typography variant="body2" component="body2" gutterBottom>
                  Mikaeil Shaghelani
                </Typography>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Typography variant="h6" component="h6" gutterBottom>
                  Total
                </Typography>
                <Typography variant="body2" component="body2" gutterBottom>
                  120 €
                </Typography>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Typography variant="h6" component="h6" gutterBottom>
                  Payment Method
                </Typography>
                <Typography variant="body2" component="body2" gutterBottom>
                  IDeal
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider variant="middle" />
          <Box p={2}>
            <Grid container spacing={4}>
              <Grid item sm={3} xs={12}>
                <Typography variant="h6" component="h6" gutterBottom>
                  1 X Peperoni Pizza
                </Typography>
                <Typography variant="body2" component="body2" gutterBottom>
                  Add more cheese
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}></Grid>
              <Grid item sm={3} xs={12}>
                <Typography variant="h6" component="h6" gutterBottom>
                  Payment Method
                </Typography>
                <Typography variant="body2" component="body2" gutterBottom>
                  IDeal
                </Typography>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Typography variant="h6" component="h6" gutterBottom>
                  1 X Onion Soup
                </Typography>
                <Typography
                  variant="body2"
                  component="body2"
                  gutterBottom
                ></Typography>
              </Grid>
              <Grid item sm={6} xs={12}></Grid>
              <Grid item sm={3} xs={12}>
                <Typography variant="h6" component="h6" gutterBottom>
                  Payment Method
                </Typography>
                <Typography variant="body2" component="body2" gutterBottom>
                  Paypal
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider variant="middle" />
          <Box p={2}>
            <Grid container spacing={4}>
              <Grid item sm={3} xs={12}>
                <Typography variant="h6" component="h6" gutterBottom>
                  Special Note
                </Typography>
                <Typography variant="body2" component="body2" gutterBottom>
                  Nothing
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}></Grid>
              <Grid item sm={3} xs={12}>
                <Typography variant="h5" component="h5" gutterBottom>
                  TOTAL €120
                </Typography>
                <Button variant="contained" className={css.rateBtn}>
                  <StarIcon /> Rate Your Order
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default SuccessOrder;
