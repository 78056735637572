
export const GET_USER = 'GET_USER'
export const GET_USER_START = 'GET_USER_START'
export const GET_USER_FAIL = 'GET_USER_FAIL'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_START = 'DELETE_USER_START'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'

export const POST_USER = 'POST_USER'
export const POST_USER_START = 'POST_USER_START'
export const POST_USER_FAIL = 'POST_USER_FAIL'
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS'

export const PUT_USER = 'PUT_USER'
export const PUT_USER_START = 'PUT_USER_START'
export const PUT_USER_FAIL = 'PUT_USER_FAIL'
export const PUT_USER_SUCCESS = 'PUT_USER_SUCCESS'