import * as actionTypes from './actionTypes'
import axios from '../../../axios/localhost'

export const putRestaurants = (updatedData) => {
 
    return dispatch => {
        console.log(updatedData)
        dispatch(putRestaurantsStart())
        let name = updatedData.name;
        axios.put(`/restaurant/${name}`, {...updatedData})
            .then(response => dispatch(putRestaurantsSuccess()))
            .catch(error => {            
                dispatch(putRestaurantsFail(error.response.data.message))
            })
    }
}

export const putRestaurantsStart = () => {
    return {
        type: actionTypes.PUT_RESTAURANTS_START
    }
}

export const putRestaurantsFail = (error) => {
    return {
        type: actionTypes.PUT_RESTAURANTS_FAIL,
        error: error
    }
}

export const putRestaurantsSuccess = () => {
    return {
        type: actionTypes.PUT_RESTAURANTS_SUCCESS,
    }
}
