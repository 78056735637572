import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Logo from "../../../assets/img/2-1-1024x622.png";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
// eslint-disable-next-line no-unused-vars
import css from "./Footer.module.scss";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <div>
      <footer>
        <Container maxWidth="lg">
          <Grid container spacing={5}>
            <Grid item xs={12} sm={3}>
              <Typography paragraph={true} variant="h6">
                {" "}
                VEGAFOODIES.NL
              </Typography>
              <Typography paragraph={true} variant="button">
                <Link to={`/terms-and-conditions`}>
                  {t("TERMS AND CONDITIONS")}{" "}
                </Link>
              </Typography>

              <Typography paragraph={true} variant="button">
                <Link to={`/contact`}>{t("Contact")} </Link>
              </Typography>

              <Typography paragraph={true} variant="button">
                <Link to={`/login`}>{t("LOG IN RESTURANT")} </Link>
              </Typography>

              <Typography paragraph={true} variant="button">
                {t("VACANCIES")}{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography paragraph={true} variant="h6">
                {" "}
                {t("CITIES")}{" "}
              </Typography>
              <Typography paragraph={true} variant="button">
                {" "}
                Amsterdam{" "}
              </Typography>
              <Typography paragraph={true} variant="button">
                {" "}
                Eindhoven{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography paragraph={true} variant="h6">
                {t("PARTNERS")}
              </Typography>
              <Typography paragraph={true} variant="button">
                <Link
                  to={{ pathname: "https://www.dierenwelzijnscheck.nl" }}
                  target="_blank"
                >
                  {" "}
                  Dierenwelzijnscheck{" "}
                </Link>
              </Typography>
              <Typography paragraph={true} variant="button">
                <Link
                  to={{ pathname: "https://mastinovegan.nl/mastino-2-0/" }}
                  target="_blank"
                >
                  Mastino 2.0
                </Link>
              </Typography>
              <Typography paragraph={true} variant="button">
                <Link
                  to={{ pathname: "https://www.veganisme.org/" }}
                  target="_blank"
                >
                  Veganisme.org{" "}
                </Link>
              </Typography>

              <Typography paragraph={true} variant="button">
                <Link
                  to={{ pathname: "https://creativs.online/" }}
                  target="_blank"
                >
                  Creativs{" "}
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography paragraph={true} align="center" variant="button">
                {" "}
                <img alt="logo" src={Logo}></img>{" "}
              </Typography>
              <Typography paragraph={true} align="center" variant="button">
                {" "}
                <InstagramIcon></InstagramIcon> <FacebookIcon></FacebookIcon>
              </Typography>
              <Typography paragraph={true} align="center" variant="button">
                {" "}
                +31 40 309 8058{" "}
              </Typography>
              <Typography paragraph={true} align="center" variant="body2">
                {" "}
                klantenservice@vegafoodies.nl
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </div>
  );
}
