import { TimePicker, Input } from "antd";
import moment from "moment";
import "antd/dist/antd.css";
const format = "HH:mm";

const getDisabledHours = () => {
  var hours = [];
  for (var i = 0; i < 10; i++) {
    hours.push(i);
  }
  return hours;
};
export const RenderTimePicker = ({ input, name, rows, label, ...rest }) => (
  <TimePicker
    {...input.value}
    {...rest}
    disabledHours={getDisabledHours}
    defaultValue={moment("12:08", format)}
    format={format}
    onChange={(event, value) => input.onChange(value)}
    name={name}
  />
);
