
export const GET_USER_ROLE = 'GET_USER_ROLE'
export const GET_USER_ROLE_START = 'GET_USER_ROLE_START'
export const GET_USER_ROLE_FAIL = 'GET_USER_ROLE_FAIL'
export const GET_USER_ROLE_SUCCESS = 'GET_USER_ROLE_SUCCESS'

export const DELETE_USER_ROLE = 'DELETE_USER_ROLE'
export const DELETE_USER_ROLE_START = 'DELETE_USER_ROLE_START'
export const DELETE_USER_ROLE_FAIL = 'DELETE_USER_ROLE_FAIL'
export const DELETE_USER_ROLE_SUCCESS = 'DELETE_USER_ROLE_SUCCESS'

export const POST_USER_ROLE = 'POST_USER_ROLE'
export const POST_USER_ROLE_START = 'POST_USER_ROLE_START'
export const POST_USER_ROLE_FAIL = 'POST_USER_ROLE_FAIL'
export const POST_USER_ROLE_SUCCESS = 'POST_USER_ROLE_SUCCESS'

export const PUT_USER_ROLE = 'PUT_USER_ROLE'
export const PUT_USER_ROLE_START = 'PUT_USER_ROLE_START'
export const PUT_USER_ROLE_FAIL = 'PUT_USER_ROLE_FAIL'
export const PUT_USER_ROLE_SUCCESS = 'PUT_USER_ROLE_SUCCESS'