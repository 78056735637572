import { React, Fragment, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import axios from "../../../../axios/localhost";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import css from "./Slider.module.scss";
import Slider from "infinite-react-carousel"; /// package is from https://github.com/g787543/infinite-react-carousel
import OutlinedInput from "@material-ui/core/OutlinedInput";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import {
  getLocation,
  addLocation,
} from "../../../../store/actions/location/locationActions";
export const Slide = (props) => {
  const { t } = useTranslation();
  let content;

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  ////
  const handleSubmit = (event) => {
    props.onAddLocation(event.target[0].value);
    event.preventDefault();
    props.history.push("/restaurants");
  };
  const [loading, setLoading] = useState(false);

  const HandleGeo = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          axios
            .get(
              `/rests/${position.coords.longitude}/${position.coords.latitude}`
            )
            .then((response) => {
              if (response) {
                setLoading(false);
                props.onAddLocation(response.data.content.customerAddress);
                props.history.push("/restaurants");
              }
            });
        },
        (error) => {
          setLoading(false);
          setState({
            open: true,
            vertical: "top",
            horizontal: "center",
          });
        }
      );
    } else {
      setLoading(false);
      setState({
        open: true,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  content = (
    <div className={css.slider}>
      <div className={css.sliderContent}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message="you location is unknown"
          key={vertical + horizontal}
        />
        <Box pb={4}>
          <Typography variant="h4" align="center">
            <Trans i18nKey="slider.part1">
              why stay hungry when you can order best vegan dishes
            </Trans>
          </Typography>
          <Typography variant="h6" align="center">
            <Trans i18nKey="slider.part2">
              Let find a vegan restaurant in your area
            </Trans>
          </Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <FormControl className={css.searchBox} variant="filled">
            <OutlinedInput
              className={css.searchInput}
              type="text"
              placeholder={t("enter street address")}
              name="q"
              endAdornment={
                <InputAdornment position="end">
                  {loading === false ? (
                    <IconButton
                      onClick={HandleGeo}
                      size="small"
                      value="test"
                      edge="end"
                    >
                      <GpsFixedIcon />
                    </IconButton>
                  ) : (
                    <CircularProgress color="#fff" size={30} disableShrink />
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
          <Button
            type="submit"
            className={css.sliderButton}
            variant="contained"
            size="small"
          >
            {t("find")}
          </Button>
        </form>
      </div>
      <Slider arrows={false} autoplay={true}>
        {props.items.map((itm) => (
          <div>
            <img alt="vegafoodies" src={itm}></img>
          </div>
        ))}
      </Slider>
    </div>
  );
  return <Fragment>{content}</Fragment>;
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetLocation: () => {
      dispatch(getLocation());
    },
    onAddLocation: (location) => {
      dispatch(addLocation(location));
    },
  };
};
export default connect(null, mapDispatchToProps)(withRouter(Slide));
