import * as actionTypes from './actionTypes'
import axios from '../../../axios/localhost'

export const putProductCategory = (updatedData) => {
 
    return dispatch => {
     
        dispatch(putProductCategoryStart())
        
        axios.put(`/productcategory`, {...updatedData})
            .then(response => dispatch(putProductCategorySuccess()))
            .catch(error => {            
                dispatch(putProductCategoryFail(error.response.data.message))
            })
    }
}

export const putProductCategoryStart = (displayComponent) => {
    return {
        type: actionTypes.PUT_PRODUCT_CATEGORY_START,
        displayComponent :displayComponent
    }
}

export const putProductCategoryFail = (error) => {
    return {
        type: actionTypes.PUT_PRODUCT_CATEGORY_FAIL,
        error: error
    }
}

export const putProductCategorySuccess = () => {
    return {
        type: actionTypes.PUT_PRODUCT_CATEGORY_SUCCESS,
    }
}
