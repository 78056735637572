import React, { useState ,Fragment } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
// import components
import LoginForm from './LoginForm/LoginForm'
import RegisterForm from './RegisterForm/RegisterForm'
import css from './Login.module.scss';
import { useTranslation } from "react-i18next";

export default function Login() {
    const { t } = useTranslation();
    const [loggingIn, setLoggingIn] = useState(true);

    const switchToRegisterHandler = props => {
        setLoggingIn(false)
    }

    const switchToLoginHandler = props => {
        setLoggingIn(true)
    }
        let activeForm = (
            <Fragment>
                <LoginForm />
             <Container component="main" maxWidth="xs">
                <Grid container justify="flex-start">
                    <Grid item>
                    <p className={css.links}  onClick={switchToRegisterHandler}>{t('Dont have an account')} {t('sign up')}?</p>
                    </Grid>
                </Grid>
              </Container>
            </Fragment>
        )

        if (!loggingIn) {
            activeForm = (
                <Fragment>
                    <RegisterForm switchToLogIn={switchToLoginHandler}/>
                    <Container component="main" maxWidth="xs">
                <Grid container justify="flex-start">
                    <Grid item>
                    <p  className={css.links} onClick={switchToLoginHandler}>{t('Already have an account')}? {t('sign in')}!</p>
                    </Grid>
                </Grid>
              </Container>
             </Fragment>
            )
        }

        return (
            <div className={css.boxWrapper}>
            
                {activeForm}

            </div>
        );
        
}

