import React from "react";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
// redux store
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/auth";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Google from "../../../../assets/img/Google.png";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),

    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    textAlign: "center",
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formWrapper: {
    width: "100%",
  },
  submit: {
    margin: theme.spacing(2, 0, 1),
  },
}));
const validate = (values) => {
  const errors = {};
  const requiredFields = ["email", "password"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address";
  }
  return errors;
};
const renderTextField = ({
  input,
  label,
  meta: { touched, invalid, error, warning },
  ...custom
}) => (
  <TextField
    variant="outlined"
    margin="normal"
    size="small"
    error={touched && invalid}
    helperText={touched && error}
    fullWidth
    id={label}
    label={label}
    name={label}
    {...input}
    {...custom}
  />
);

let SignupForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  let error = null;
  const { handleSubmit } = props;

  if (props.error) {
    error = <Alert severity="error">{props.error}</Alert>;
  }

  if (props.registrationMessage) {
    if (!props.registrationSuccessful) {
      error = <Alert severity="error">{props.registrationMessage}</Alert>;
    } else {
      error = <Alert severity="success">{props.registrationMessage}</Alert>;
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {error}
        <Typography align="center" component="h1" variant="h5">
          {t("sign up")}
        </Typography>

        <form onSubmit={handleSubmit}>
          <Field
            label="Email"
            name="email"
            type="email"
            component={renderTextField}
          />
          <Field
            label="password"
            name="password"
            type="password"
            component={renderTextField}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {props.loading === false ? (
              <div>{t("sign up")}</div>
            ) : (
              <CircularProgress color="#fff" size={30} disableShrink />
            )}
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="default"
            className={classes.submit}
            startIcon={<Avatar className={classes.small} src={Google} />}
          >
            {t("sign up")} {t("with")} Google
          </Button>
        </form>
      </div>
    </Container>
  );
};

SignupForm = reduxForm({
  form: "signup",
  validate,
})(SignupForm);

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    loading: state.auth.loading,
    registrationSuccessful: state.auth.registrationSuccessful,
    registrationMessage: state.auth.registrationMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (values) => dispatch(actions.register(values)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SignupForm));
