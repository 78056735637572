import * as actionTypes from '../../actions/restaurants/OpeningHours/actionTypes'
import produce from 'immer'

const initialState = {
    openinghours: null,
 
    error: null,
    loading: false,

}

const reducer = (state = initialState, action) => 
produce(state, draft => {
    switch(action.type) {

        case actionTypes.GET_OPENING_HOURS_START:
            draft.loading = true;
            draft.error = null;
            draft.editing = false;

            break;
        case actionTypes.GET_OPENING_HOURS_FAIL:
            draft.loading = false;
            draft.error = action.error;
            break;
        case actionTypes.GET_OPENING_HOURS_SUCCESS:
            draft.loading = false;
            draft.openinghours = action.openinghours
            break;
       
 

       
      
    }
})


 
export default reducer;