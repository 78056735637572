import * as actionTypes from "./actionTypes";
import axios from "../../../axios/localhost";

export const getRestaurants = () => {
  return (dispatch) => {
    dispatch(getRestaurantsStart());

    axios
      .get(`/restaurants`)
      .then((response) => dispatch(getRestaurantsSuccess(response.data)))
      .catch((error) => {
        dispatch(getRestaurantsFail(error.data));
      });
  };
};

export const getRestaurantsStart = () => {
  return {
    type: actionTypes.GET_RESTAURANTS_START,
  };
};

export const getRestaurantsFail = (error) => {
  return {
    type: actionTypes.GET_RESTAURANTS_FAIL,
    error: error,
  };
};

export const getRestaurantsSuccess = (data) => {
  return {
    type: actionTypes.GET_RESTAURANTS_SUCCESS,
    restaurants: data.content,
  };
};

//get a single restaurant  with matching restaurant name
export const getRestaurant = (name) => {
  return (dispatch) => {
    dispatch(getRestaurantstart());

    axios
      .get(`/restaurant/${name}`)
      .then((response) => {
        dispatch(
          getRestaurantsuccess({
            ...response.data,
          })
        );
      })
      .catch((error) => {
        dispatch(getRestaurantFail(error.message));
      });
  };
};

export const getRestaurantstart = () => {
  return {
    type: actionTypes.GET_RESTAURANT_START,
  };
};

export const getRestaurantFail = (error) => {
  return {
    type: actionTypes.GET_RESTAURANT_FAIL,
    error: error,
  };
};

export const getRestaurantsuccess = (menu) => {
  return {
    type: actionTypes.GET_RESTAURANT_SUCCESS,
    menu: menu,
  };
};
//finding  restaurants by user's address
export const getRestaurantCustomerAddress = (customerAddress) => {
  return (dispatch) => {
    dispatch(getRestaurantCustomerAddressstart());

    axios
      .get(`/rests/${customerAddress}`)
      .then((response) => {
        dispatch(
          getRestaurantCustomerAddresssuccess({
            ...response.data,
          })
        );
      })
      .catch((error) => {
        dispatch(getRestaurantCustomerAddressFail(error.message));
      });
  };
};

export const getRestaurantCustomerAddressstart = () => {
  return {
    type: actionTypes.GET_RESTAURANT_ADDRESS_START,
  };
};

export const getRestaurantCustomerAddressFail = (error) => {
  return {
    type: actionTypes.GET_RESTAURANT_ADDRESS_FAIL,
    error: error,
  };
};

export const getRestaurantCustomerAddresssuccess = (items) => {
  return {
    type: actionTypes.GET_RESTAURANT_ADDRESS_SUCCESS,
    items: items,
  };
};
//finding  restaurants by user's Geo location
export const getRestaurantCustomerLocation = (longitude, latitude) => {
  return (dispatch) => {
    dispatch(getRestaurantCustomerLocationstart());

    axios
      .get(`/rests/${longitude}/${latitude}`)
      .then((response) => {
        dispatch(
          getRestaurantCustomerLocationsuccess({
            ...response.data,
          })
        );
      })
      .catch((error) => {
        dispatch(getRestaurantCustomerLocationFail(error.message));
      });
  };
};

export const getRestaurantCustomerLocationstart = () => {
  return {
    type: actionTypes.GET_RESTAURANT_LOCATION_START,
  };
};

export const getRestaurantCustomerLocationFail = (error) => {
  return {
    type: actionTypes.GET_RESTAURANT_LOCATION_FAIL,
    error: error,
  };
};

export const getRestaurantCustomerLocationsuccess = (location) => {
  return {
    type: actionTypes.GET_RESTAURANT_LOCATION_SUCCESS,
    location: location.content.customerAddress,
  };
};
