import React from 'react'
import DashboardIcon from '@material-ui/icons/Dashboard';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import CategoryIcon from '@material-ui/icons/Category';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import PeopleIcon from '@material-ui/icons/People';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import PanoramaOutlinedIcon from '@material-ui/icons/PanoramaOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
///imports components
const Dashboard = React.lazy(() => import('../views/dashboard/Dashboard'))
const Restaurants = React.lazy(() => import('../views/dashboard/admin/restaurants/Restaurants'))
const ProductCategory = React.lazy(() => import('../views/dashboard/admin/productCategory/ProductCategory'))
const User = React.lazy(() => import('../views/dashboard/admin/user/User'))


export const routes = [
    {
        text: 'dashboard',
        icon : <DashboardIcon />,
        link: '/dashboard',
        exact: true,
        component: Dashboard
    },

    {
        text: 'Restaurant',
        icon : <RestaurantIcon />,
        link: '/restaurants',
        exact: true,
        component: Restaurants
    },
    {
        text: 'Orders',
        icon : <AddShoppingCartIcon />,
        link: '/orders',
        exact: true,
        component: Restaurants
    },
    {
        text: 'Categories',
        icon : <CategoryIcon />,
        link: '/categories',
        exact: true,
        component: ProductCategory
    },
    {
        text: 'Products',
        icon : <RestaurantMenuIcon />,
        link: '/products',
        exact: true,
        component: Restaurants
    },
    {
        text: 'Users',
        icon : <PeopleIcon />,
        link: '/users',
        exact: true,
        component: User
    },
    {
        text: 'Reviews',
        icon : <StarHalfIcon />,
        link: '/reviews',
        exact: true,
        component: Restaurants
    },
    {
        text: 'Slider Pictures',
        icon : <PanoramaOutlinedIcon />,
        link: '/slider',
        exact: true,
        component: Restaurants
    },
    {
        text: 'Conact Form',
        icon : <MailOutlineIcon />,
        link: '/contact',
        exact: true,
        component: Restaurants
    },
]