import React, { useState, useEffect, Suspense } from "react";
import { connect } from "react-redux";
import css from "./App.module.scss";
import Loading from "./utility/loading/Loading";
// import utility functions
import { getRoutes } from "./utility/getRoutes";

// import static pages
import Terms from "./views/staticPages/Terms";
// import main application views
import { authCheckState } from "./store/actions/auth";
import Home from "./views/main-application/Home/Home";
import Login from "./views/main-application/Login/Login";
import Contact from "./views/main-application/Contact/Contact";
import Menu from "./views/main-application/Restaurant/Menu/Menu";
import Restaurants from "./views/main-application/Restaurant/Restaurants/Restaurants";
import Checkout from "./views/main-application/Cart/Checkout/Checkout";
import SuccessOrder from "./views/main-application/Cart/Checkout/SuccessOrder/SuccessOrder";
import LandingPageLayout from "./layouts/main-application/LandingPageLayout";
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
// import from packages
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import SuccessSnackbar from "./components/snackbars/snackbarSuccess";
import ErrorSnackBar from "./components/snackbars/snackbarError";
function App(props) {
  let match = useRouteMatch();
  useEffect(() => {
    props.checkAuthState();
  }, [props.userID]);

  // const deleteLocalStorage = () => {
  //   localStorage.clear();
  // };
  // useEffect(() => {
  //   // TODO: learn how git works for real!
  //   window.addEventListener("beforeunload", deleteLocalStorage);
  //   return () => {
  //     window.removeEventListener("beforeunload", deleteLocalStorage);
  //   };
  // }, []);

  let app = (
    <Suspense fallback={<Loading />}>
      <LandingPageLayout>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path={"/login"} exact component={Login} />
          <Route path="/contact" exact component={Contact} />
          <Route path={`${match.path}/menu/:name`} exact component={Menu} />
          <Route path="/menu/:name" exact component={Menu} />
          <Route path="/restaurants" exact component={Restaurants} />
          <Route path="/checkout" exact component={Checkout} />
          <Route path="/terms-and-conditions" exact component={Terms} />
          <Route path="/successorder" exact component={SuccessOrder} />
        </Switch>
      </LandingPageLayout>
    </Suspense>
  );

  if (props.userID) {
    let roleRoutes = getRoutes(props.roles);

    const routes = roleRoutes.routes.map((route, key) => (
      <Route
        key={key}
        path={route.link}
        component={route.component}
        exact={route.exact}
      />
    ));

    app = (
      <Suspense fallback={<Loading />}>
        <DashboardLayout>
          {routes}
          <Redirect to="/" />
        </DashboardLayout>
      </Suspense>
    );
    // return app;
  }
  return (
    <div className={css.App}>
      <BrowserRouter>
        <Switch>{app}</Switch>
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userID: state.auth.userID,
    roles: state.auth.roles,
    email: state.auth.email,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkAuthState: () => dispatch(authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
