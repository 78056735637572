import React from "react";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import Grid from "@material-ui/core/Grid";
import Slider from "infinite-react-carousel"; /// package is from https://github.com/g787543/infinite-react-carousel
import css from "./Testimonial.module.scss";

import avatar from "../../../../assets/img/1.jpg";

export default function Testimonial(props) {
  return (
    <div className={css.testimonial}>
      <Slider autoplay={true} arrows={false}>
      {props.items.map((itm) => (
        <div>
          <Grid item sm={6} xs={12}>
            <Box bgcolor="#fff" boxShadow={3} p={10} m={5} elevation={3}>
              <Avatar className={css.avatar} src={itm.img} />
              <Typography align="center" variant="subtitle1">
                <FormatQuoteIcon> </FormatQuoteIcon>
                    {itm.text}
                <FormatQuoteIcon> </FormatQuoteIcon>
              </Typography>
              <Typography align="right" variant="h6">
                {itm.name}
              </Typography>
              <div className={css.ResturantName}>
                <Typography align="center" variant="h5">
                 {itm.restaurantName}
                </Typography>
              </div>
            </Box>
          </Grid>
        </div>
        ))}
      </Slider>
    </div>
  );
}
