import * as actionTypes from "../../actions/order/actionTypes";
import produce from "immer";

const initialState = {
  order: null,
  error: null,
  loading: false,
  update: false,
  displayComponent: null,
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.GET_ORDER_START:
        draft.loading = true;
        draft.error = null;
        break;
      case actionTypes.GET_ORDER_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actionTypes.GET_ORDER_SUCCESS:
        draft.loading = false;
        draft.order = action.order;
        break;
      //////////////////////
      case actionTypes.GET_ORDER_POSSIBLETOPAY_START:
        draft.loading = true;
        draft.error = null;
        break;
      case actionTypes.GET_ORDER_POSSIBLETOPAY_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actionTypes.GET_ORDER_POSSIBLETOPAY_SUCCESS:
        draft.loading = false;
        draft.possibletopay = action.possibletopay;
        break;
      /////////////////////
      case actionTypes.GET_ORDER_CUSTOMERADDRESS_START:
        draft.loading = true;
        draft.error = null;
        break;
      case actionTypes.GET_ORDER_CUSTOMERADDRESS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actionTypes.GET_ORDER_CUSTOMERADDRESS_SUCCESS:
        draft.loading = false;
        draft.CustomerAddress = action.CustomerAddress;

        break;
      /////////////////////
      case actionTypes.POST_ORDER_START:
        draft.loading = true;
        break;
      case actionTypes.POST_ORDER_SUCCESS:
        if (action.data.content === false) {
          draft.order.message.push(action.data.message);
        }

        break;
      ///////////////
      case actionTypes.PUT_ORDER_START:
        draft.loading = true;
        draft.displayComponent = "create";
        break;
      case actionTypes.PUT_ORDER_SUCCESS:
        draft.loading = false;
        if (action.data) {
          draft.order.content.push(action.data);
        }
        draft.update = true;

        break;

      default:
    }
  });

export default reducer;
