import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n"; //localization
import thunk from "redux-thunk"; //Redux
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import authReducer from "./store/reducers/auth";
import cartReducer from "./store/reducers/cart/cartReducer";
import productsReducer from "./store/reducers/products/productsReducer";
import restaurantsReducer from "./store/reducers/restaurants/restaurantsReducer";
import openinghoursReducer from "./store/reducers/openinghours/openinghoursReducer";
import productCategoryReducer from "./store/reducers/productCategory/productCategoryReducer";
import userReducer from "./store/reducers/user/userReducer";
import orderReducer from "./store/reducers/order/orderReducer";
import locationReducer from "./store/reducers/location/locationReducer";
import contactReducer from "./store/reducers/contact/contactReducer";
import userRoleReducer from "./store/reducers/userRole/userRoleReducer";
import snackbarReducer from "./store/reducers/snackbars-global/snackbars";
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  products: productsReducer,
  restaurants: restaurantsReducer,
  openinghours: openinghoursReducer,
  productCategories: productCategoryReducer,
  snackbar: snackbarReducer,
  user: userReducer,
  userRole: userRoleReducer,
  order: orderReducer,
  location: locationReducer,
  contact: contactReducer,
  form: formReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
