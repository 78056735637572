export const GET_CONTACT = "GET_CONTACT";
export const GET_CONTACT_START = "GET_CONTACT_START";
export const GET_CONTACT_FAIL = "GET_CONTACT_FAIL";
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS";

export const DELETE_CONTACT = "DELETE_CONTACT";
export const DELETE_CONTACT_START = "DELETE_CONTACT_START";
export const DELETE_CONTACT_FAIL = "DELETE_CONTACT_FAIL";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";

export const POST_CONTACT = "POST_CONTACT";
export const POST_CONTACT_START = "POST_CONTACT_START";
export const POST_CONTACT_FAIL = "POST_CONTACT_FAIL";
export const POST_CONTACT_SUCCESS = "POST_CONTACT_SUCCESS";

export const PUT_CONTACT = "PUT_CONTACT";
export const PUT_CONTACT_START = "PUT_CONTACT_START";
export const PUT_CONTACT_FAIL = "PUT_CONTACT_FAIL";
export const PUT_CONTACT_SUCCESS = "PUT_CONTACT_SUCCESS";
