import React from "react";
import { Switch, Redirect, Route, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";

import css from "./Resturants.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
  },
  logo: {
    float: "right",
    paddingLeft: 10,
    width: 80,
    height: 80,
  },
}));

export const Restaurants = (props) => {
  const classes = useStyles();
  let body = null;

  if (props.items) {
    body = (
      <React.Fragment>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            {props.items.map((itm) => (
              <Grid key={itm.id} item sm={6} xs={12}>
                <Link to={`menu/${itm.name}`}>
                  <Paper className={classes.paper}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <div>
                          <img
                            className={css.resturanImg}
                            alt="complex"
                            src={itm.image}
                          />
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={css.ResturantHolder}
                        sm
                        container
                      >
                        <Grid item xs container direction="column" spacing={2}>
                          <Grid item xs>
                            <Typography
                              display="inline"
                              gutterBottom
                              variant="h6"
                            >
                              {itm.name}
                            </Typography>
                            <Typography
                              display="inline"
                              gutterBottom
                              variant="subtitle1"
                            >
                              <img
                                className={classes.logo}
                                alt="complex"
                                src={itm.logo}
                              />
                            </Typography>

                            <Typography>
                              <Rating
                                name="read-only"
                                value={itm.reviewAVG}
                                readOnly
                              />
                            </Typography>
                            <Typography
                              display="inline"
                              gutterBottom
                              variant="subtitle1"
                            >
                              {itm.isOpen === false && (
                                <Chip
                                  size="small"
                                  variant="outlined"
                                  icon={<CloseIcon />}
                                  label="Closed"
                                  color="secondary"
                                />
                              )}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                              {itm.description}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
  return <div>{body}</div>;
};

export default Restaurants;
