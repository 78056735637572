import React, { useEffect, Fragment, useState } from "react";
import { connect } from "react-redux";
import Location from "../../Location/Location";
import { getProductCategory } from "../../../../store/actions/productCategory";
import css from "./Restaurants.module.scss";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Resturants from "../../Home/Resturants/Resturants";
import { useTranslation } from "react-i18next";
import Loading from "../../../../utility/loading/Loading";
import RestaurantSkeleton from "../../../../utility/RestaurantSkeleton/RestaurantSkeleton";
import Restaurant from "./Restaurant/Restaurant";
////categories tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

export const Restaurants = (props) => {
  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: "#ffbd2f",
      },
    },
  });
  const { t, i18n } = useTranslation();
  let allItems;
  let categories = null;
  //////// for categories tabs
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (props.loading === false) {
    if (props.items) {
      allItems = (
        <TabPanel value={value} index={0}>
          <Resturants items={props.items.restaurants}></Resturants>
        </TabPanel>
      );
    } else {
      allItems = (
        <Container maxWidth="lg">
          <Box p={5}>
            <Alert severity="error">There is no restaurant in this area!</Alert>
          </Box>
        </Container>
      );
    }
  } else {
    allItems = <RestaurantSkeleton />;
  }

  //////
  useEffect(() => {
    //// get list of categories
    props.onGetProductCategory();
  }, []);

  if (props.productCategories) {
    categories = props.productCategories.map((itm) => (
      <li key={itm.id} className={css.category}>
        {" "}
        {itm.nameEN}
      </li>
    ));
  }

  if (props.productCategories) {
    return (
      <div>
        <Fragment>
          <Box className={css.boxWrapper}>
            <MuiThemeProvider theme={theme}>
              <AppBar
                className={css.menuBar}
                position="static"
                color="transparent"
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="on"
                  indicatorColor="secondary"
                  textColor="secondary"
                  aria-label="scrollable force tabs example"
                >
                  <Tab label={t("View All")} {...a11yProps(0)} />
                  {props.productCategories.map((itm, index) => (
                    <Tab
                      className={css.category}
                      label={i18n.language == "nl" ? itm.nameNL : itm.nameEN}
                      {...a11yProps(index + 1)}
                    />
                  ))}
                </Tabs>
              </AppBar>
            </MuiThemeProvider>
            <Container maxWidth="lg">
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Location />
                </Grid>
              </Grid>
            </Container>
            {allItems}
            {props.items &&
              props.productCategories.map((itm, index) => (
                <TabPanel value={value} index={index + 1}>
                  {props.items.restaurants
                    .filter((p) =>
                      p.productCategories.some((s) => s.id === itm.id)
                    )
                    .map((h) => (
                      <Restaurant item={h} />
                    ))}
                </TabPanel>
              ))}
          </Box>
        </Fragment>
      </div>
    );
  } else {
    return (
      <div>
        <Loading />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    items: state.restaurants.items,
    loading: state.restaurants.loading,
    productCategories: state.productCategories.productCategories,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetProductCategory: () => dispatch(getProductCategory()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Restaurants);
