import * as actionTypes from './actionTypes'
import axios from '../../../axios/localhost'

export const getProductCategory = () => {
    return dispatch => {
        dispatch(getProductCategoryStart())

        axios.get(`/productcategory`)
            .then(response => dispatch(getProductCategorySuccess(response.data)))
            
            .catch(error => {              
                dispatch(getProductCategoryFail(error.data))
            })
    }
}

export const getProductCategoryStart = () => {
    return {
        type: actionTypes.GET_PRODUCT_CATEGORY_START
    }
}

export const getProductCategoryFail = (error) => {
    return {
        type: actionTypes.GET_PRODUCT_CATEGORY_FAIL,
        error: error
    }
}

export const getProductCategorySuccess = (data) => {
    return {
        type: actionTypes.GET_PRODUCT_CATEGORY_SUCCESS,
        productCategories: data.content
    }
}

