import * as actionTypes from "./actionTypes";
import axios from "../../../axios/localhost";

export const postOrder = (data) => {
  return (dispatch) => {
    dispatch(postOrderStart());
    let orderID = 2;
    axios
      .post(`/order/${orderID}`, { orderID: orderID, ...data })
      .then((response) => dispatch(postOrderSuccess(response)))
      .catch((error) => {
        dispatch(postOrderFail(error));
      });
  };
};

export const postOrderStart = () => {
  return {
    type: actionTypes.POST_ORDER_START,
  };
};

export const postOrderFail = (error) => {
  return {
    type: actionTypes.POST_ORDER_FAIL,
    error: error,
  };
};

export const postOrderSuccess = (response) => {
  if (response.data.content !== false) {
    return () => {
      axios
        .get(`/order/ispossibletopay/${response.data.content.id}`)
        .then(
          (response) => (window.location = response.data._links.checkout.href)
        );
    };
  } else {
    return {
      type: actionTypes.POST_ORDER_SUCCESS,
      data: response.data,
    };
  }
};
