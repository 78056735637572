import * as actionTypes from '../actions/auth/actionTypes';
import produce from 'immer';

const initialState = {
    token: null,
    userID: null,

    error: null,
    loading: false,
    registrationSuccessful: false,
    registrationMessage: null
}

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.LOGIN_START:
                draft.loading = true;
                draft.error = null;
                break;

            case actionTypes.LOGIN_FAIL:
                draft.loading = false;
                draft.error = action.error;
                break;

            case actionTypes.LOGIN_SUCCESS:
                draft.loading = false;
                draft.token = action.token;
                draft.userID = action.userID;
                draft.roles = action.roles;
                draft.firstName = action.firstName
                draft.lastName = action.lastName
                break;

            case actionTypes.AUTH_LOGOUT:
                draft.token = null;
                draft.userID = null;
                break;

            case actionTypes.REGISTER_START:
                draft.loading = true;
                draft.error = null;
                break;

            case actionTypes.REGISTER_SUCCESS:
                draft.loading = false;
                draft.registrationSuccessful = true;
                draft.registrationMessage = action.message;
                break;

            case actionTypes.REGISTER_FAIL:
                draft.loading = false;
                draft.registrationSuccessful = false;
                draft.registrationMessage = action.message;
                break;

            default:
        }
    });

export default reducer;