export const GET_RESTAURANTS = 'GET_RESTAURANTS'
export const GET_RESTAURANTS_START = 'GET_RESTAURANTS_START'
export const GET_RESTAURANTS_FAIL = 'GET_RESTAURANTS_FAIL'
export const GET_RESTAURANTS_SUCCESS = 'GET_RESTAURANTS_SUCCESS'

export const GET_RESTAURANT = 'GET_RESTAURANT'
export const GET_RESTAURANT_START = 'GET_RESTAURANT_START'
export const GET_RESTAURANT_FAIL = 'GET_RESTAURANT_FAIL'
export const GET_RESTAURANT_SUCCESS = 'GET_RESTAURANT_SUCCESS'

export const GET_RESTAURANT_ADDRESS = 'GET_RESTAURANT_ADDRESS_'
export const GET_RESTAURANT_ADDRESS_START = 'GET_RESTAURANT_ADDRESS_START'
export const GET_RESTAURANT_ADDRESS_FAIL = 'GET_RESTAURANT_ADDRESS_FAIL'
export const GET_RESTAURANT_ADDRESS_SUCCESS = 'GET_RESTAURANT_ADDRESS_SUCCESS'

export const GET_RESTAURANT_LOCATION = 'GET_RESTAURANT_LOCATION'
export const GET_RESTAURANT_LOCATION_START = 'GET_RESTAURANT_LOCATION_START'
export const GET_RESTAURANT_LOCATION_FAIL = 'GET_RESTAURANT_LOCATION_FAIL'
export const GET_RESTAURANT_LOCATION_SUCCESS = 'GET_RESTAURANT_LOCATION_SUCCESS'

export const DELETE_RESTAURANTS = 'DELETE_RESTAURANTS'
export const DELETE_RESTAURANTS_START = 'DELETE_RESTAURANTS_START'
export const DELETE_RESTAURANTS_FAIL = 'DELETE_RESTAURANTS_FAIL'
export const DELETE_RESTAURANTS_SUCCESS = 'DELETE_RESTAURANTS_SUCCESS'

export const POST_RESTAURANTS = 'POST_RESTAURANTS'
export const POST_RESTAURANTS_START = 'POST_RESTAURANTS_START'
export const POST_RESTAURANTS_FAIL = 'POST_RESTAURANTS_FAIL'
export const POST_RESTAURANTS_SUCCESS = 'POST_RESTAURANTS_SUCCESS'

export const PUT_RESTAURANTS = 'PUT_RESTAURANTS'
export const PUT_RESTAURANTS_START = 'PUT_RESTAURANTS_START'
export const PUT_RESTAURANTS_FAIL = 'PUT_RESTAURANTS_FAIL'
export const PUT_RESTAURANTS_SUCCESS = 'PUT_RESTAURANTS_SUCCESS'

export const POST_COVER_IMAGE = 'POST_COVER_IMAGE'
export const POST_COVER_IMAGE_START = 'POST_COVER_IMAGE_START'
export const POST_COVER_IMAGE_FAIL = 'POST_COVER_IMAGE_FAIL'
export const POST_COVER_IMAGE_SUCCESS = 'POST_COVER_IMAGE_SUCCESS'

export const POST_LOGO_IMAGE = 'POST_LOGO_IMAGE'
export const POST_LOGO_IMAGE_START = 'POST_LOGO_IMAGE_START'
export const POST_LOGO_IMAGE_FAIL = 'POST_LOGO_IMAGE_FAIL'
export const POST_LOGO_IMAGE_SUCCESS = 'POST_LOGO_IMAGE_SUCCESS'