import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import css from "./Terms.module.scss";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";



export const Terms = ()=>{
    const { t } = useTranslation();
return(

     <div className={css.boxWrapper}>
        <Container>
            <Box p={5}>

           
         <Typography variant="h5"> {t("terms.part1")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part2")} </Typography>
         <Typography variant="h5" > {t("terms.part3")} </Typography>

         <Typography variant="body1" paragraph={true}> {t("terms.part4")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part5")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part6")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part7")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part8")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part9")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part10")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part11")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part12")} </Typography>
         <Typography variant="h5"> {t("terms.part13")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part14")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part15")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part16")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part17")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part18")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part19")} </Typography>

         <Typography variant="h5" > {t("terms.part20")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part21")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part22")} </Typography>
         <Typography variant="h5" > {t("terms.part23")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part24")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part25")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part26")} </Typography>
         <Typography variant="h5" > {t("terms.part27")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part28")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part29")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part30")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part31")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part32")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part33")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part34")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part35")} </Typography>
         <Typography variant="h5" > {t("terms.part36")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part37")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part38")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part39")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part40")} </Typography>
         <Typography variant="h5" > {t("terms.part41")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part42")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part43")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part44")} </Typography>
         <Typography variant="h5" > {t("terms.part45")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part46")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part47")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part48")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part49")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part50")} </Typography>
         <Typography variant="h5" > {t("terms.part51")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part52")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part53")} </Typography>
         <Typography variant="h5" > {t("terms.part54")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part55")} </Typography>
         <Typography variant="h5" > {t("terms.part56")} </Typography>
         <Typography variant="body1" paragraph={true}> {t("terms.part57")} </Typography>
         </Box>
        </Container>
        
    </div>
)
   
}



export default Terms;