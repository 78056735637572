import {React,Fragment} from 'react';
import css from './RestaurantSkeleton.module.scss'
import CardHeader from "@material-ui/core/CardHeader";
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Skeleton from '@material-ui/lab/Skeleton';
const RestaurantSkeleton = (props) => {
    return (
        <Fragment>
            <Container  className={css.boxWrapper}  maxWidth="lg">
            <Grid container spacing={2}>
            <Grid item sm={6} xs={12} >
            <Card>
            <CardHeader  
            avatar={( <Skeleton animation="wave" variant="rect" width={100} height={100} />)}
            title = {(<Skeleton animation="wave" height={17} width="80%" style={{ marginBottom: 6 }} />)}
            subheader = {(<Skeleton animation="wave" height={14} width="60%" style={{ marginBottom: 6 }} />)}
            >
            </CardHeader>
            </Card>
            </Grid>
            <Grid item sm={6} xs={12}>
            <Card>
                <CardHeader  
                avatar={( <Skeleton animation="wave" variant="rect" width={100} height={100} />)}
                title = {(<Skeleton animation="wave" height={17} width="80%" style={{ marginBottom: 6 }} />)}
                subheader = {(<Skeleton animation="wave" height={14} width="60%" style={{ marginBottom: 6 }} />)}
                >
                </CardHeader>
            </Card>
            </Grid>
            <Grid item sm={6} xs={12}>
            <Card>
                <CardHeader  
                avatar={( <Skeleton animation="wave" variant="rect" width={100} height={100} />)}
                title = {(<Skeleton animation="wave" height={17} width="80%" style={{ marginBottom: 6 }} />)}
                subheader = {(<Skeleton animation="wave" height={14} width="60%" style={{ marginBottom: 6 }} />)}
                >
                </CardHeader>
            </Card>
            </Grid>
            </Grid>

            </Container>
            </Fragment>

    );
}

export default RestaurantSkeleton;