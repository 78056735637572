import { GET_LOCATION, ADD_LOCATION } from "./actionTypes";

export const getLocation = () => {
  return {
    type: GET_LOCATION,
  };
};
export const addLocation = (location) => {
  return {
    type: ADD_LOCATION,
    location: location,
  };
};
