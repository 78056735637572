import * as actionTypes from './actionTypes'
import axios from '../../../../axios/localhost'

export const postCoverImage = (resID, selectedFile) => {
   const data = [ selectedFile]
   const formData = new FormData();
    formData.append('file',selectedFile)
   
    const config = {     
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(postCoverImageStart())

        axios.post(`/resimage/${resID}`, formData , config)
            .then(response => {  
                dispatch(postCoverImageSuccess({
                        ...response.data
                    }
                ))
            })
            .catch(error => {             
                dispatch(postCoverImageFail(error.response.data.message))
            })
    }
}

export const postCoverImageStart = () => {
    return {
        type: actionTypes.POST_COVER_IMAGE_START
    }
}

export const postCoverImageFail = (error) => {
    return {
        type: actionTypes.POST_COVER_IMAGE_FAIL,
        error: error
    }
}

export const postCoverImageSuccess = (info) => {
   
    return {
        type: actionTypes.POST_COVER_IMAGE_SUCCESS,
      
        
    }
}
