import * as actionTypes from '../../actions/user/actionTypes'
import produce from 'immer'

const initialState = {
    user: null,
    error: null,
    loading: false,
    update:false,
    displayComponent:null

}

const reducer = (state = initialState, action) => 
produce(state, draft => {
    switch(action.type) {

        case actionTypes.GET_USER_START:
            draft.loading = true;
            draft.error = null;
            draft.update = false;
            draft.displayComponent= 'view';
            break;
        case actionTypes.GET_USER_FAIL:
            draft.loading = false;
            draft.error = action.error;
            break;
        case actionTypes.GET_USER_SUCCESS:
            draft.loading = false;
            draft.displayComponent= 'view';
            draft.user = action.user
            break;


         case actionTypes.POST_USER_START:
                draft.loading = true;
                draft.displayComponent= 'create';
                break;
         case actionTypes.POST_USER_SUCCESS:
                draft.loading = false;
                if(action.data){
                    draft.user.content.push(action.data)
                }
                draft.update= true;
                draft.displayComponent= 'view';
            break;
        case actionTypes.PUT_USER_START:
                draft.loading = false;
                draft.displayComponent= 'edit';
              
            break;
        case actionTypes.PUT_USER_SUCCESS:
                draft.loading = false;
                draft.displayComponent= 'view';
                draft.update= true;
              
            break;
        default:
 
       
      
    }
})


 
export default reducer;