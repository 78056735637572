import React from "react";
import css from "./Loading.module.scss";
import Typography from "@material-ui/core/Typography";
import gif from "../../assets/img/loading.gif";
const Loading = (props) => {
  return (
    <div className={css.preloader}>
      <div className={css.cooking}>
        <div className={css.bubble}></div>
        <div className={css.bubble}></div>
        <div className={css.bubble}></div>
        <div className={css.bubble}></div>
        <div className={css.bubble}></div>
        <div className={css.area}>
          <div className={css.slides}>
            <div className={css.pan}></div>
            <div className={css.handle}></div>
          </div>
          <div className={css.pancake}>
            <div className={css.pastry}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
