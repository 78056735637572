import TextField from "@material-ui/core/TextField";
export const RenderTextField = ({
  input,
  name,

  rows,
  label,
  meta: { touched, invalid, error, warning },
  ...custom
}) => (
  <TextField
    variant="outlined"
    margin="normal"
    error={touched && invalid}
    helperText={touched && error}
    fullWidth
    multiline
    size="small"
    rows={rows}
    id={label}
    label={label}
    name={name}
    autoFocus
    {...input}
    {...custom}
  />
);
