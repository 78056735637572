import { React } from "react";
import { RenderTextField } from "../../../components/Input/RenderTextField";

import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import css from "./Contact.module.scss";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LocalMallTwoToneIcon from "@material-ui/icons/LocalMallTwoTone";
import TextField from "@material-ui/core/TextField";
import RadioButton from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { Container, FormGroup } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { FormControl } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import DateFnsUtils from "@date-io/date-fns";

import { postContact } from "../../../store/actions/contact";

let Contact = (props) => {
  const { t } = useTranslation();
  const { handleSubmit } = props;
  return (
    <div className={css.wrapper}>
      <Container>
        <Grid container spacing={4}>
          <Grid item sm={8} xs={12}>
            <Grid container spacing={1}></Grid>
            <Box p={2} mt={2} mb={3} boxShadow={3} bgcolor="white">
              <Typography paragraph={true} variant="h5">
                Contact
              </Typography>
              {t("Contacttext")}
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item sm={6} xs={12}>
                    <Field
                      label="Email"
                      name="email"
                      type="text"
                      autoComplete="off"
                      component={RenderTextField}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Field
                      label="Title"
                      name="title"
                      type="text"
                      autoComplete="off"
                      component={RenderTextField}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <Field
                      label="Special notes"
                      name="content"
                      type="textarea"
                      autoComplete="off"
                      rows={10}
                      component={RenderTextField}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <Button color="primary" variant="contained" type="submit">
                      {t("send")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>

          <Grid item sm={4} xs={12}></Grid>
        </Grid>
      </Container>
    </div>
  );
};
Contact = reduxForm({
  form: "Contact",
})(Contact);

const mapStateToProps = (state) => {
  return {
    items: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (values) => dispatch(postContact(values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
