import * as actionTypes from "./actionTypes";
import axios from "../../../axios/localhost";

export const postContact = (data) => {
  return (dispatch) => {
    dispatch(postContactStart());

    axios
      .post(`/contact`, { ...data })
      .then((response) => dispatch(postContactSuccess(data)))
      .catch((error) => {
        dispatch(postContactFail(error.response.data.message));
      });
  };
};

export const postContactStart = (displayComponent) => {
  return {
    type: actionTypes.POST_CONTACT_START,
  };
};

export const postContactFail = (error) => {
  return {
    type: actionTypes.POST_CONTACT_FAIL,
    error: error,
  };
};

export const postContactSuccess = (data) => {
  return {
    type: actionTypes.POST_CONTACT_SUCCESS,
    data: data,
  };
};
