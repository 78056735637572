import React from "react";

// import components

import Header from "./header/Header";
import Footer from "./footer/Footer";
import css from "./LandingPageLayout.module.scss";

export default function LandingPageLayout(props) {
  return (
    <div>
      <Header></Header>
      <main className={css.wrapper}>{props.children}</main>
      <Footer></Footer>
    </div>
  );
}
