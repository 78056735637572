import React, { useState, useEffect } from "react";
import { RenderTextField } from "../../../../components/Input/RenderTextField";
import { RenderCheckbox } from "../../../../components/Input/RenderCheckbox";
import { RenderTimePicker } from "../../../../components/Input/RenderTimePicker";
import { RenderRadioGroup } from "../../../../components/Input/RenderRadioGroup";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import css from "./Checkout.module.scss";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LocalMallTwoToneIcon from "@material-ui/icons/LocalMallTwoTone";
import TextField from "@material-ui/core/TextField";
import RadioButton from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { FormGroup } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { FormControl } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { loadState, saveState } from "../../../../localStorage";
import DateFnsUtils from "@date-io/date-fns";

import { postOrder } from "../../../../store/actions/order";
import { orderItems } from "../../../../store/actions/cart/cartActions";
import { getLocation } from "../../../../store/actions/location/locationActions";

let Checkout = (props) => {
  let deliveryCost;
  const [selectedDate, handleDateChange] = useState(new Date());
  if (loadState("deliveryCost")) {
    let temp = loadState("deliveryCost");
    deliveryCost = temp.deliveryCost;
  }

  useEffect(() => {
    props.onGetLocation();
    props.onOrderItems();
  }, []);

  const [cartItems, setCartItems] = React.useState(props.items.addedItems);

  const { handleSubmit } = props;
  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let addedItems = cartItems.length ? (
    cartItems.map((item) => {
      return (
        <div className="collection-item avatar" key={item.id}>
          <List component="nav">
            <ListItem>
              <ListItemIcon className={css.qty}>
                {item.quantity + "x"}
              </ListItemIcon>

              <ListItemText
                className={css.productName}
                primary={item.name}
              ></ListItemText>
              <ListItemText
                className={css.qty}
                primary={"€" + item.price.toLocaleString()}
              />
            </ListItem>
          </List>
        </div>
      );
    })
  ) : (
    <Typography align="center" variant="h6">
      {t("Your basket is empty")}{" "}
    </Typography>
  );

  return (
    <div className={css.wrapper}>
      <Grid container spacing={4}>
        <Grid item sm={8} xs={12}>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>
                    Returning costumer? Click here to login
                  </Typography>
                </AccordionSummary>
                <Box p={2} pt={0}>
                  <Typography>
                    <FormGroup>
                      <FormControl>
                        <TextField
                          fullWidth={true}
                          size="small"
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                        />
                        <TextField
                          fullWidth={true}
                          margin="normal"
                          size="small"
                          id="outlined-basic"
                          label="Password"
                          variant="outlined"
                        />
                        <Button
                          fullWidth={true}
                          variant="contained"
                          color="primary"
                          size="medium"
                        >
                          Login
                        </Button>
                      </FormControl>
                    </FormGroup>
                  </Typography>
                </Box>
              </Accordion>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography>Do you have a discount coupon?</Typography>
                </AccordionSummary>
                <Box p={2} pt={0}>
                  <Typography>
                    <FormGroup>
                      <FormControl>
                        <TextField
                          fullWidth={true}
                          margin="normal"
                          size="small"
                          id="outlined-basic"
                          label="Coupon Code"
                          variant="outlined"
                        />
                        <Button
                          fullWidth={true}
                          variant="contained"
                          color="primary"
                          size="medium"
                        >
                          Apply coupon
                        </Button>
                      </FormControl>
                    </FormGroup>
                  </Typography>
                </Box>
              </Accordion>
            </Grid>
          </Grid>
          <Box p={2} mt={2} mb={3} boxShadow={3} bgcolor="white">
            <Typography paragraph={true} variant="h5">
              Billing and Shipping
            </Typography>

            <form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <Field
                    label="Name"
                    name="firstName"
                    type="text"
                    autoComplete="off"
                    component={RenderTextField}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Field
                    label="Last name"
                    name="lastName"
                    type="text"
                    autoComplete="off"
                    component={RenderTextField}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Field
                    label="Phone"
                    name="phone"
                    type="text"
                    autoComplete="off"
                    component={RenderTextField}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Field
                    label="E-mail address"
                    name="email"
                    type="text"
                    autoComplete="off"
                    component={RenderTextField}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <TextField
                    id="outlined-textarea"
                    label="Delivery Address"
                    multiline
                    size="small"
                    variant="outlined"
                    disabled={true}
                    fullWidth
                    value={props.location}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Field
                    label="Special notes"
                    name="note"
                    type="textarea"
                    autoComplete="off"
                    component={RenderTextField}
                  />
                </Grid>
                <Grid item sm={6} xs={6}>
                  <FormLabel component="legend">
                    How would you like to get your food?
                  </FormLabel>

                  <Field name="orderType" component={RenderRadioGroup}>
                    <FormControlLabel
                      value="pickup"
                      control={<Radio />}
                      label="Pickup"
                    />
                    <FormControlLabel
                      value="delivery"
                      control={<Radio />}
                      label="Delivery"
                    />
                  </Field>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormLabel component="legend">
                    please select your delivery time
                  </FormLabel>
                  <Field
                    name="deliveryTime"
                    className={css.deliveryTime}
                    component={RenderTimePicker}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Field
                    label="I would like to be informed once a month about all deals, new
                  restaurants and more!"
                    name="newsletter"
                    component={RenderCheckbox}
                  />
                </Grid>

                <Grid item sm={12} xs={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    className={css.orderBtn}
                  >
                    {props.loading === false ? (
                      <div> {t(" place an order")}</div>
                    ) : (
                      <CircularProgress color="#fff" size={30} disableShrink />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Box boxShadow={3} bgcolor="white" p={1}>
            <Typography
              gutterBottom={true}
              align="center"
              color="textSecondary"
              variant="h6"
            >
              <LocalMallTwoToneIcon />{" "}
              <span className={css.bold}> {t("your basket")}</span>
            </Typography>
            <Divider />
            {addedItems}
            <Divider />
            <ListItem>
              <ListItemText>
                <span className={css.bold}>{t("subtotal")}</span>
              </ListItemText>
              <Typography align="center" variant="body1">
                {props.items.total}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemText>
                <span className={css.bold}>{t("shipping")}</span>
              </ListItemText>
              <Typography align="center" variant="body1">
                {deliveryCost && deliveryCost}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemText>
                <span className={css.bold}>{t("total")}</span>
              </ListItemText>
              <Typography align="center" variant="body1">
                {deliveryCost
                  ? props.items.total + deliveryCost
                  : props.items.total}
              </Typography>
            </ListItem>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

Checkout = reduxForm({
  form: "Checkout",
  enableReinitialize: true,
})(Checkout);

const mapStateToProps = (state, orderItems) => {
  return {
    items: state.cart,
    loading: state.order.loading,
    location: state.location.location,
    order: state.cart.orderItems,
    initialValues: {
      orderItems: state.cart.orderItems,
      restaurantID: state.cart.restaurantID,
      orderID: 2,
      deliveryAddress: state.location.location,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetLocation: () => dispatch(getLocation()),
    onOrderItems: () => dispatch(orderItems()),
    onSubmit: (values) => dispatch(postOrder(values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
