import * as actionTypes from './actionTypes'
import axios from '../../../../axios/localhost'

export const postLogoImage = (resID, selectedFile) => {
 
   const formData = new FormData();
    formData.append('file',selectedFile)
   console.log(selectedFile)
    const config = {     
        headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
        dispatch(postLogoImageStart())

        axios.post(`/reslogo/${resID}`, formData , config)
            .then(response => {  
                dispatch(postLogoImageSuccess({
                        ...response.data
                    }
                ))
            })
            .catch(error => {             
                dispatch(postLogoImageFail(error.response.data.message))
            })
    }
}

export const postLogoImageStart = () => {
    return {
        type: actionTypes.POST_LOGO_IMAGE_START
    }
}

export const postLogoImageFail = (error) => {
    return {
        type: actionTypes.POST_LOGO_IMAGE_FAIL,
        error: error
    }
}

export const postLogoImageSuccess = (info) => {
   
    return {
        type: actionTypes.POST_LOGO_IMAGE_SUCCESS,
      
        
    }
}
