import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SUB_QUANTITY,
  ADD_QUANTITY,
  EMPTY_CART,
  UPDATE_NOTE,
  ORDER_ITEMS,
} from "./actionTypes";

export const addToCart = (id, price, name, restaurantID, restaurantName) => {
  return {
    type: ADD_TO_CART,
    id: id,
    price: price,
    name: name,
    restaurantID: restaurantID,
    restaurantName: restaurantName,
    note: "",
  };
};
export const removeFromCart = (id) => {
  return {
    type: REMOVE_FROM_CART,
    id,
  };
};
export const subtractQuantity = (id) => {
  return {
    type: SUB_QUANTITY,
    id,
  };
};
export const addQuantity = (id) => {
  return {
    type: ADD_QUANTITY,
    id,
  };
};
export const emptyCart = () => {
  return {
    type: EMPTY_CART,
  };
};

export const updateNote = (id, value) => {
  return {
    type: UPDATE_NOTE,
    id,
    value,
  };
};

export const orderItems = () => {
  return {
    type: ORDER_ITEMS,
  };
};
