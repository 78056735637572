import * as actionTypes from "../../actions/contact/actionTypes";
import produce from "immer";

const initialState = {
  contact: null,
  error: null,
  loading: false,
  update: false,
  displayComponent: null,
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.GET_CONTACT_START:
        draft.loading = true;
        draft.error = null;
        draft.update = false;
        draft.displayComponent = "view";
        break;
      case actionTypes.GET_CONTACT_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actionTypes.GET_CONTACT_SUCCESS:
        draft.loading = false;
        draft.displayComponent = "view";
        draft.contact = action.contact;
        break;

      case actionTypes.POST_CONTACT_START:
        draft.loading = true;
        draft.displayComponent = "create";
        break;
      case actionTypes.POST_CONTACT_SUCCESS:
        draft.loading = false;
        if (action.data) {
          draft.contact.content.push(action.data);
        }
        draft.update = true;
        draft.displayComponent = "view";
        break;

      default:
    }
  });

export default reducer;
