import React, { useEffect, useState } from "react";
import axios from "../../../axios/localhost";
import { withRouter, Route } from "react-router-dom";
import Loading from "../../../utility/loading/Loading";
import { getRestaurants } from "../../../store/actions/restaurants";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import css from "./Home.module.scss";
import Slide from "./Slide/Slide";
import TopFood from "./TopFood/TopFood";
import Testimonial from "./Testimonial/Testimonial";
import Resturants from "./Resturants/Resturants";
import HowWorks from "./HowWorks/HowWorks";
import Download from "./Download/Download";

import LocalDiningIcon from "@material-ui/icons/LocalDining";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";

export const Home = (props) => {
  const [items, setItems] = useState();

  useEffect(() => {
    axios.get(`/landing`).then((response) => {
      setItems(response.data);
    });
  }, []);

  const { t } = useTranslation();
  let theme = createMuiTheme();

  theme = responsiveFontSizes(theme);
  if (items) {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Slide items={items.sliderImages}></Slide>
          <div className={css.wrapper}>
            <Typography
              p={5}
              m={2}
              variant="h4"
              className={css.homeTitle}
              align="center"
            >
              {" "}
              {t("Popular")} {t("Dishes")}{" "}
            </Typography>
            <div className={css.dividerHolder}>
              <span className={css.divider}>
                <LocalDiningIcon />
              </span>
            </div>
            <TopFood items={items.products}></TopFood>
          </div>

          <Typography
            m={2}
            variant="h4"
            className={css.homeTitle}
            align="center"
          >
            {" "}
            {t("Popular")} {t("Restaurant")}{" "}
          </Typography>
          <div className={css.dividerHolder}>
            <span className={css.divider}>
              <LocalDiningIcon />
            </span>
          </div>
          <Resturants items={items.restaurants}></Resturants>
          <Typography variant="h4" className={css.homeTitle} align="center">
            {" "}
            {t("What customer opinion")}{" "}
          </Typography>
          <div className={css.dividerHolder}>
            <span className={css.divider}>
              <LocalDiningIcon />
            </span>
          </div>
          <Testimonial items={items.testimonials}></Testimonial>
          <Typography
            m={5}
            variant="h4"
            className={css.homeTitle}
            align="center"
          >
            {" "}
            {t("How does it work")}
          </Typography>
          <div className={css.dividerHolder}>
            <span className={css.divider}>
              <LocalDiningIcon />
            </span>
          </div>
          <HowWorks></HowWorks>
          {/* <Download></Download> */}
        </ThemeProvider>
      </div>
    );
  } else {
    return (
      <div>
        <Loading />
      </div>
    );
  }
};

export default withRouter(Home);
