import React, { useState, useEffect, Fragment, useRef } from "react";
import { connect } from "react-redux";
import axios from "../../../axios/localhost";
import css from "./Location.module.css";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import { useTranslation } from "react-i18next";
import { getRestaurantCustomerAddress } from "../../../store/actions/restaurants";
import {
  getLocation,
  addLocation,
} from "../../../store/actions/location/locationActions";

const useStyles = makeStyles((theme) => ({
  input: {
    marginLeft: theme.spacing(1),

    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Location = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const HandleGeo = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      axios
        .get(`/rests/${position.coords.longitude}/${position.coords.latitude}`)
        .then((response) => {
          if (response) {
            props.onAddLocation(response.data.content.customerAddress);
            props.history.push("/restaurants");
          }
        });
    });
  };

  ////for location Dialog Box
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //////////

  useEffect(() => {
    props.onGetLocation(); //// load location from localstorage
    props.onGetRestaurantCustomerAddress(props.location); //// get restaurant near to the address
  }, [props.location]);

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onAddLocation(event.target[0].value);
    setOpen(false);
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <div className={css.locationWrapper}>
            <div className={css.locationIcon}>
              {" "}
              <RoomOutlinedIcon />{" "}
            </div>{" "}
            <Button onClick={handleClickOpen} className={css.locationText}>
              {props.location} <ExpandMoreIcon />
            </Button>
          </div>
        </Grid>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-slide-description">
            {" "}
            Please enter your address to see from which restaurants and stores
            we deliver to you{" "}
          </DialogContentText>
          <Grid container>
            <Grid className={css.location} item sm={12} xs={12}>
              <form onSubmit={handleSubmit}>
                <FormControl
                  className={css.searchBox}
                  size="small"
                  variant="filled"
                >
                  <OutlinedInput
                    className={css.searchInput}
                    type="text"
                    placeholder={t("enter street address")}
                    name="q"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={HandleGeo} size="small" edge="end">
                          <GpsFixedIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Button
                  type="submit"
                  className={css.locationButton}
                  variant="contained"
                  size="small"
                >
                  {t("find")}
                </Button>
              </form>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    items: state.restaurants.items,

    location: state.location.location,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAddLocation: (location) => {
      dispatch(addLocation(location));
    },
    onGetLocation: () => {
      dispatch(getLocation());
    },
    onGetRestaurantCustomerAddress: (customerAddress) =>
      dispatch(getRestaurantCustomerAddress(customerAddress)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Location);
