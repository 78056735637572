import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import { withRouter, Route } from "react-router-dom";

import css from "./Restaurant.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
  },
  logo: {
    float: "right",
    paddingLeft: 10,
    width: 80,
    height: 80,
  },
}));

export const Restaurant = (props) => {
  const classes = useStyles();
  let body = null;
  if (props.item) {
    body = (
      <React.Fragment>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid key={props.item.id} item sm={6} xs={12}>
              <Link to={`menu/${props.item.name}`}>
                <Paper className={classes.paper}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <div>
                        <img
                          className={css.resturanImg}
                          alt="complex"
                          src={props.item.image}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={css.ResturantHolder}
                      sm
                      container
                    >
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                          <Typography
                            display="inline"
                            gutterBottom
                            variant="h6"
                          >
                            {props.item.name}
                          </Typography>
                          <Typography
                            display="inline"
                            gutterBottom
                            variant="subtitle1"
                          >
                            <img
                              className={classes.logo}
                              alt="complex"
                              src={props.item.logo}
                            />
                          </Typography>
                          <Typography>
                            <Rating
                              name="read-only"
                              value={props.item.reviewAVG}
                              readOnly
                            />
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            {props.item.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
  return <div>{body}</div>;
};

export default withRouter(Restaurant);
