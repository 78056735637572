import * as actionTypes from './actionTypes'
import axios from '../../../axios/localhost'

export const getOrder = (id) => {
    return dispatch => {
        dispatch(getOrderStart())

        axios.get(`/order/${id}`)
            .then(response => dispatch(getOrderSuccess(response.data)))
            .catch(error => {              
                dispatch(getOrderFail(error.data))
            })
    }
}

export const getOrderStart = () => {
    return {
        type: actionTypes.GET_ORDER_START
    }
}

export const getOrderFail = (error) => {
    return {
        type: actionTypes.GET_ORDER_FAIL,
        error: error
    }
}

export const getOrderSuccess = (data) => {
    return {
        type: actionTypes.GET_ORDER_SUCCESS,
        order: data
    }
}

//check if order ispossible to pay or not
export const getOrderpossibletopay = (id) => {
    return (dispatch) => {
      dispatch(getOrderpossibletopayStart());
  
      axios
        .get(`/order/ispossibletopay/${id}`)
        .then((response) => {
          dispatch(
            getOrderpossibletopaySuccess({
              ...response.data,
            })
          );
        })
        .catch((error) => {
          dispatch(getOrderpossibletopayFail(error.message));
        });
    };
  };
  
  export const getOrderpossibletopayStart = () => {
    return {
        type: actionTypes.GET_ORDER_POSSIBLETOPAY_START
    }
}

export const getOrderpossibletopayFail = (error) => {
    return {
        type: actionTypes.GET_ORDER_POSSIBLETOPAY_FAIL,
        error: error
    }
}

export const getOrderpossibletopaySuccess = (data) => {
    return {
        type: actionTypes.GET_ORDER_POSSIBLETOPAY_SUCCESS,
        possibletopay: data
    }
}

  //check if user is allowed to place the order
export const getOrderCustomerAddress = (customerAddress,restaurantID) => {
    return (dispatch) => {
      dispatch(getOrderCustomerAddressStart());
  
      axios
        .get(`/${customerAddress}/${restaurantID}`)
        .then((response) => {
          dispatch(
            getOrderCustomerAddressSuccess({
              ...response.data,
            })
          );
        })
        .catch((error) => {
          dispatch(getOrderCustomerAddressFail(error.message));
        });
    };
  };
  
  export const getOrderCustomerAddressStart = () => {
    return {
        type: actionTypes.GET_ORDER_CUSTOMERADDRESS_START
    }
}

export const getOrderCustomerAddressFail = (error) => {
    return {
        type: actionTypes.GET_ORDER_CUSTOMERADDRESS_FAIL,
        error: error
    }
}

export const getOrderCustomerAddressSuccess = (data) => {
    return {
        type: actionTypes.GET_ORDER_CUSTOMERADDRESS_SUCCESS,
        CustomerAddress: data
    }
}
