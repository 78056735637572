import React, { useEffect, useState, Fragment, useRef } from "react";
import { connect } from "react-redux";
import { withRouter, Route } from "react-router-dom";
import { getRestaurant } from "../../../../store/actions/restaurants";
import Location from "../../Location/Location";
import Cart from "../../Cart/Cart";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import Slide from "@material-ui/core/Slide";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import css from "./Menu.module.scss";
import defaultImg from "../../../../assets/img/food.png";
import { addToCart } from "../../../../store/actions/cart/cartActions";
import { useTranslation } from "react-i18next";
import Loading from "../../../../utility/loading/Loading";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import Chip from "@material-ui/core/Chip";
///for basket
const TransitionBasket = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
////////
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -5,
    top: 8,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: "0 6px",
  },
}))(Badge);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  paper: {
    marginTop: 15,
    width: "100%",
    minHeight: 100,
    justifyContent: "center",
  },
  price: {
    float: "right",
    paddingLeft: 10,
    color: "#FC9005",
    fontSize: 20,
    fontWeight: 600,
  },
  button: {
    background: "#FF8E53",
    padding: 2,
    borderRadius: 3,
    textTransform: "capitalize",
    fontWeight: 600,
    border: 0,
    color: "white",
  },
  mainHolder: {
    backgroundColor: "#f8f9fa",
    position: "relative",
    paddingBottom: 15,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  ///// for working hours modal
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Menu = (props) => {
  let NoteContent = {};
  // for selected color tabs
  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: "#ffbd2f",
      },
    },
  });

  useEffect(() => {
    props.onGetRestaurant(props.match.params.name);
  }, []);
  //////for basket
  const [openBasket, setOpenBasket] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const handleClickOpenBasket = () => {
    setOpenBasket(true);
  };
  const handleCloseBasket = () => {
    setOpenBasket(false);
  };
  ////more info of restaurant
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //////// for categories tabs
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  ////creating new basket
  const [openNewBasket, setOpenNewBasket] = useState(false);
  const [basketValues, setBasketValues] = useState();
  const handleCloseNewBasket = () => {
    setOpenNewBasket(false);
  };
  const handleNewBasket = () => {
    props.addToCart(
      basketValues.id,
      basketValues.price,
      basketValues.name,
      basketValues.restaurantsID,
      basketValues.restaurantsName
    );
    setOpenNewBasket(false);
  };
  ////////
  const AddtoCart = (id, price, name) => {
    if (props.restaurant.isOpen === false) {
      setOpenAlert(true);
    } else {
      if (
        props.items.restaurantID !== props.restaurant.id &&
        props.items.restaurantID !== 0
      ) {
        setBasketValues({
          id: id,
          price: price,
          name: name,
          restaurantsID: props.restaurant.id,
          restaurantsName: props.restaurant.name,
        });
        setOpenNewBasket(true);
      } else {
        props.addToCart(
          id,
          price,
          name,
          props.restaurant.id,
          props.restaurant.name
        );
      }
    }
  };

  const { t, i18n } = useTranslation();

  const classes = useStyles();
  if (props.restaurant) {
    return (
      <Fragment>
        <Dialog
          open={openNewBasket}
          onClose={handleCloseNewBasket}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {t("Create new basket?")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("This will clear your existing basket with")}{" "}
              {props.restaurant.name} {t("and start a new basket")}
            </DialogContentText>
          </DialogContent>
          <Divider></Divider>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleCloseNewBasket}
              variant="outlined"
              color="primary"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={handleNewBasket}
              variant="contained"
              color="primary"
            >
              {t("create new basket")}
            </Button>
          </DialogActions>
        </Dialog>

        <div className={classes.mainHolder}>
          <div
            className={css.coverImg}
            style={{
              backgroundImage: "url(" + props.restaurant.image + ")",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              minHeight: 177,
              opacity: 0.8,
            }}
          >
            <div className={css.restaurantContent}>
              <Container className={css.container} maxWidth="lg">
                <Grid container className={css.reverse} spacing={1}>
                  <Grid className={css.contentWrapper} item sm={10} xs={12}>
                    <Typography variant="h5" gutterBottom>
                      {props.restaurant.name}
                    </Typography>
                    <Typography>
                      <Rating
                        name="read-only"
                        value={props.restaurant.reviewAVG}
                        readOnly
                      />
                    </Typography>

                    {props.restaurant.isOpen === false && (
                      <Typography display="inline" variant="subtitle1">
                        <Chip
                          size="small"
                          variant="outlined"
                          icon={<CloseIcon />}
                          label="Closed"
                          color="secondary"
                        />
                      </Typography>
                    )}

                    <Typography gutterBottom>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleClickOpen}
                      >
                        <InfoOutlinedIcon />
                      </Button>
                    </Typography>
                    <Typography color="inherit" variant="body2" gutterBottom>
                      {i18n.language == "nl"
                        ? props.restaurant.descriptionNL
                        : props.restaurant.descriptionEN}
                    </Typography>
                    <Chip
                      className={css.chip}
                      icon={<EuroSymbolIcon />}
                      label={t("min order") + ": " + props.restaurant.minOrder}
                      size="small"
                    />
                    <Chip
                      className={css.chip}
                      icon={<EuroSymbolIcon />}
                      label={
                        t("max delivery") + ": " + props.restaurant.maxDelivery
                      }
                      size="small"
                    />

                    <Dialog
                      open={open}
                      maxWidth="sm"
                      fullWidth={true}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-slide-title"
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogContent maxWidth="lg">
                        <DialogContentText id="alert-dialog-slide-description">
                          <Typography variant="h5" gutterBottom>
                            {t("Opening hours")}{" "}
                          </Typography>
                          <Divider></Divider>
                          {props.restaurant.openingHours.map((itm) => (
                            <Fragment>
                              <Typography variant="subtitle2" gutterBottom>
                                {t(itm.weekDay)} : {itm.fromHour} - {itm.toHour}
                              </Typography>
                            </Fragment>
                          ))}
                          <Divider></Divider>
                          <Typography variant="subtitle2" gutterBottom>
                            <LocationOnIcon /> {props.restaurant.city}{" "}
                            {props.restaurant.address}
                          </Typography>
                          <Typography variant="subtitle2" gutterBottom>
                            <PhoneAndroidIcon /> {props.restaurant.mobile}{" "}
                            <PhoneIcon />
                            {props.restaurant.phone}
                          </Typography>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          {t("close")}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>

                  <Grid
                    item
                    sm={2}
                    alignItems="center"
                    alignContent="center"
                    xs={12}
                  >
                    <div className={css.avataHolder}>
                      <img
                        alt={props.restaurant.name}
                        src={props.restaurant.logo}
                      />
                    </div>

                    <Location />
                  </Grid>
                </Grid>
              </Container>
            </div>
          </div>
          <Grid container>
            <Grid item sm={8} xs={12}>
              <MuiThemeProvider theme={theme}>
                <AppBar
                  className={css.menuBar}
                  position="static"
                  color="transparent"
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="scrollable force tabs example"
                  >
                    <Tab label={t("View All")} {...a11yProps(0)} />
                    {props.restaurant.productCategories.map((itm, index) => (
                      <Tab
                        label={i18n.language == "nl" ? itm.nameNL : itm.nameEN}
                        {...a11yProps(index + 1)}
                      />
                    ))}
                  </Tabs>
                </AppBar>
              </MuiThemeProvider>
              <Box m={2}>
                <Collapse in={openAlert}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpenAlert(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {props.restaurant.name} isn't currently accepting orders
                  </Alert>
                </Collapse>
              </Box>
              <TabPanel value={value} index={0} className={css.boxWrapper}>
                <Grid container spacing={1}>
                  {props.restaurant.productCategories.map((itm) => (
                    <Fragment>
                      <Grid item sm={12} direction="row" xs={12}>
                        <Typography
                          variant="h5"
                          className={css.categories}
                          gutterBottom
                        >
                          {i18n.language == "nl" ? itm.nameNL : itm.nameEN}
                        </Typography>
                        <Divider />
                      </Grid>
                      {props.restaurant.products
                        .filter((catID) => catID.productCategoryID === itm.id)
                        .map((res) => (
                          <Grid item sm={12} xs={12}>
                            <Paper
                              onClick={() => {
                                AddtoCart(res.id, res.price, res.name);
                              }}
                              className={classes.paper}
                            >
                              <Grid container spacing={1}>
                                <Grid item>
                                  <div className={css.foodImg}>
                                    <img
                                      className={css.mainImg}
                                      src={res.image ? res.image : defaultImg}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm container>
                                  <Grid
                                    item
                                    xs
                                    container
                                    direction="column"
                                    spacing={1}
                                  >
                                    <Grid className={css.addBtn} item xs>
                                      <Typography
                                        display="inline"
                                        gutterBottom
                                        variant="subtitle1"
                                      >
                                        {res.name}
                                      </Typography>
                                      <Typography
                                        className={classes.price}
                                        display="inline"
                                        gutterBottom
                                        variant="subtitle1"
                                      >
                                        {res.price}€
                                      </Typography>
                                      <Typography variant="body2" gutterBottom>
                                        {i18n.language == "nl"
                                          ? res.ingredientNL
                                          : res.ingredientEN}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        ))}
                    </Fragment>
                  ))}
                </Grid>
              </TabPanel>
              {props.restaurant.productCategories.map((itm, index) => (
                <TabPanel
                  value={value}
                  index={index + 1}
                  className={css.boxWrapper}
                >
                  <Fragment>
                    <Grid item sm={12} direction="row" xs={12}>
                      <Typography
                        variant="h5"
                        className={css.categories}
                        gutterBottom
                      >
                        {i18n.language == "nl" ? itm.nameNL : itm.nameEN}
                      </Typography>
                      <Divider />
                    </Grid>
                    {props.restaurant.products
                      .filter((catID) => catID.productCategoryID === itm.id)
                      .map((result) => (
                        <Grid item sm={12} xs={12}>
                          <Paper className={classes.paper}>
                            <Grid container spacing={1}>
                              <Grid item>
                                <div className={css.foodImg}>
                                  <img
                                    className={css.mainImg}
                                    src={
                                      result.image ? result.image : defaultImg
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm container>
                                <Grid
                                  item
                                  xs
                                  container
                                  direction="column"
                                  spacing={1}
                                >
                                  <Grid
                                    className={css.addBtn}
                                    onClick={() => {
                                      AddtoCart(
                                        result.id,
                                        result.price,
                                        result.name
                                      );
                                    }}
                                    item
                                    xs
                                  >
                                    <Typography
                                      display="inline"
                                      gutterBottom
                                      variant="subtitle1"
                                    >
                                      {result.name}
                                    </Typography>
                                    <Typography
                                      className={classes.price}
                                      display="inline"
                                      gutterBottom
                                      variant="subtitle1"
                                    >
                                      {result.price}€
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                      {i18n.language == "nl"
                                        ? result.ingredientNL
                                        : result.ingredientEN}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      ))}
                  </Fragment>
                </TabPanel>
              ))}
            </Grid>
            <Grid item sm={4} xs={12}>
              <Box
                display={{ xs: "none", sm: "block" }}
                bgcolor="background.paper"
                color="text.primary"
                p={0}
                boxShadow={2}
                position="sticky"
                top="1%"
                zIndex="modal"
              >
                <Cart />
              </Box>
            </Grid>
          </Grid>

          {/* mobile basket */}

          <Box
            compoent="div"
            m={0}
            width="100%"
            position="fixed"
            bottom={0}
            display={{ xs: "block", sm: "none" }}
          >
            <Button
              fullWidth
              className={css.basketButt}
              onClick={handleClickOpenBasket}
            >
              <Grid container>
                <Grid item sm={4} xs={4}>
                  <IconButton aria-label="cart">
                    <StyledBadge
                      badgeContent={props.items.addedItems.length}
                      color="primary"
                    >
                      <LocalMallIcon style={{ color: "#fff" }} />
                    </StyledBadge>
                  </IconButton>
                </Grid>
                <Grid item sm={4} xs={4}>
                  <Typography variant="body1">View Basket</Typography>
                </Grid>
                <Grid item sm={4} xs={4}>
                  <Typography variant="body1">€ {props.items.total}</Typography>
                </Grid>
              </Grid>
            </Button>
          </Box>
          <Dialog
            fullScreen
            open={openBasket}
            onClose={handleCloseBasket}
            TransitionComponent={TransitionBasket}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseBasket}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
            <Cart />
          </Dialog>
        </div>
      </Fragment>
    );
  } else {
    return (
      <div>
        <Loading />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    items: state.cart,
    restaurant: state.restaurants.menu,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetRestaurant: (name) => dispatch(getRestaurant(name)),
    addToCart: (id, price, name, restaurantID, restaurantName) => {
      dispatch(addToCart(id, price, name, restaurantID, restaurantName));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));
