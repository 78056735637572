import * as actionTypes from "../../actions/restaurants/actionTypes";
import produce from "immer";

const initialState = {
  restaurants: null,
  error: null,
  loading: true,
  update: false,
  location: null,
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.GET_RESTAURANTS_START:
        draft.loading = true;
        draft.error = null;
        draft.update = false;

        break;
      case actionTypes.GET_RESTAURANTS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actionTypes.GET_RESTAURANTS_SUCCESS:
        draft.loading = false;
        draft.restaurants = action.restaurants;
        break;
      /////////////////get a single restaurant
      case actionTypes.GET_RESTAURANT_START:
        draft.loading = true;
        draft.error = null;
        draft.editing = false;

        break;
      case actionTypes.GET_RESTAURANT_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actionTypes.GET_RESTAURANT_SUCCESS:
        draft.loading = false;
        draft.menu = action.menu.content;

        break;
      //////get restaurant near to the customerAddress
      case actionTypes.GET_RESTAURANT_ADDRESS:
        draft.loading = true;
        draft.items = null;
        break;
      case actionTypes.GET_RESTAURANT_ADDRESS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actionTypes.GET_RESTAURANT_ADDRESS_SUCCESS:
        draft.loading = false;
        draft.items = action.items.content;
        break;
      //////get address of the user GEO location
      case actionTypes.GET_RESTAURANT_LOCATION_START:
        draft.loading = true;
        break;
      case actionTypes.GET_RESTAURANT_LOCATION_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case actionTypes.GET_RESTAURANT_LOCATION_SUCCESS:
        draft.loading = false;
        draft.location = action.location;
        break;
      /// for  changing cover photo of restaurant
      case actionTypes.POST_COVER_IMAGE_SUCCESS:
        draft.update = true;

        break;

      case actionTypes.POST_LOGO_IMAGE_SUCCESS: /// for changing logo of restaurant
        draft.update = true;
        break;
      default:
    }
  });

export default reducer;
