import * as actionTypes from '../../actions/userRole/actionTypes'
import produce from 'immer'

const initialState = {
    userRole: null,
    error: null,
    loading: false,
    update:false,
    displayComponent:null

}

const reducer = (state = initialState, action) => 
produce(state, draft => {
    switch(action.type) {

        case actionTypes.PUT_USER_ROLE_START:
            draft.loading = true;
            draft.error = null;
            draft.update = false;
            draft.displayComponent= 'view';
            break;
        case actionTypes.PUT_USER_ROLE_FAIL:
            draft.loading = false;
            draft.error = action.error;
            break;
        case actionTypes.PUT_USER_ROLE_SUCCESS:
            draft.loading = false;
            draft.displayComponent= 'view';
            draft.userRole = action.user
            break;


         case actionTypes.POST_USER_ROLE_START:
                draft.loading = true;
                draft.displayComponent= 'create';
                break;
         case actionTypes.POST_USER_ROLE_SUCCESS:
                draft.loading = false;
                if(action.data){
                    draft.userRole.content.push(action.data)
                }
                draft.update= true;
                draft.displayComponent= 'view';
            break;



            case actionTypes.GET_USER_ROLE_START:
                draft.loading = true;
                draft.error = null;
                draft.update = false;
                draft.displayComponent= 'view';
                break;
            case actionTypes.GET_USER_ROLE_FAIL:
                draft.loading = false;
                draft.error = action.error;
                break;
            case actionTypes.GET_USER_ROLE_SUCCESS:
              
                draft.loading = false;
                draft.displayComponent= 'view';
                draft.userRole = action.userRole
                break;



        default:
 
       
      
    }
})


 
export default reducer;