import * as actionTypes from './actionTypes'
import axios from '../../../axios/localhost'

export const postProductCategory = (data) => {

    return dispatch => {
        dispatch(postProductCategoryStart())
    
        axios.post(`/productcategory`, {  ...data })
            .then(response => dispatch(postProductCategorySuccess(data)))
            .catch(error => {
                dispatch(postProductCategoryFail(error.response.data.message))
            })
    }
}

export const postProductCategoryStart = (displayComponent) => {
    return {
        type: actionTypes.POST_PRODUCT_CATEGORY_START,
        displayComponent:displayComponent
    }
}

export const postProductCategoryFail = (error) => {
    return {
        type: actionTypes.POST_PRODUCT_CATEGORY_FAIL,
        error: error
    }
}

export const postProductCategorySuccess = (data) => {
    
    return {
        type: actionTypes.POST_PRODUCT_CATEGORY_SUCCESS,
        data: data
    }
}
