import React, { useState, Fragment, useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import css from "./Cart.module.scss";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Snackbar from "@material-ui/core/Snackbar";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteOutlineSharpIcon from "@material-ui/icons/DeleteOutlineSharp";
import Collapse from "@material-ui/core/Collapse";
import { useTranslation } from "react-i18next";
import { saveState, loadState } from "../../../localStorage";
import {
  removeFromCart,
  updateNote,
  addQuantity,
  subtractQuantity,
} from "../../../store/actions/cart/cartActions";
import {
  postOrder,
  getOrderCustomerAddress,
} from "../../../store/actions/order";
import { getLocation } from "../../../store/actions/location/locationActions";

export const Cart = (props) => {
  const [expanded, setExpanded] = useState({});

  /////// input change for note
  let notArray = [];

  props.items.addedItems.map((item) => {
    notArray.push(item.note);
  });
  const [notvalue, setnotevalue] = useState();

  useEffect(() => {
    setnotevalue(notArray);
  }, []);

  useEffect(() => {
    ///get location from state
    props.onGetLocation();
  }, []);

  useEffect(() => {
    //// check if user is allowd to order or not
    if (props.address) {
      props.onGetOrderCustomerAddress(props.address, props.items.restaurantID);
    }
  }, [props.address]);

  const handleChange = (e) => {
    setnotevalue({
      [e.target.name]: e.target.value,
    });
  };
  const handleInputChange = (event) => {
    ////save note value in the localStorage
    event.preventDefault();

    props.onUpdateNote(event.target[0].name, event.target[0].value);
  };
  ///// for notifying user

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  ////
  const { t } = useTranslation();

  const handleClick = (id) => {
    setExpanded({
      ...expanded,
      [id]: !expanded[id],
    });
  };

  //to remove the item completely
  const handleRemove = (id) => {
    props.removeFromCart(id);
  };
  //to add the quantity
  const handleAddQuantity = (id) => {
    props.addQuantity(id);
  };
  //to substruct from the quantity
  const handleSubtractQuantity = (id) => {
    props.subtractQuantity(id);
  };
  //////

  const { vertical, horizontal, open } = state;

  const handleOrder = () => {
    if (props.OrderCustomerAddress) {
      //// if it return true create the order

      if (props.OrderCustomerAddress.content.isPossible === true) {
        saveState("deliveryCost", {
          deliveryCost: props.OrderCustomerAddress.content.deliveryCost,
        });
        props.history.push("/checkout");
      } else {
        setState({
          open: true,
          vertical: "top",
          horizontal: "center",
        });
      }
    } else {
      setState({
        open: true,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  let addedItems = props.items.addedItems.length ? (
    props.items.addedItems.map((item) => {
      return (
        <div className="collection-item avatar" key={item.id}>
          <List component="nav" aria-label="main mailbox folders">
            <ListItem>
              <ListItemIcon
                className={css.removeBtn}
                onClick={() => {
                  handleRemove(item.id);
                }}
              >
                {" "}
                <DeleteOutlineSharpIcon />
              </ListItemIcon>
              <ListItemIcon className={css.qty}>
                {" "}
                {item.quantity + "x"}
              </ListItemIcon>
              <ListItemIcon
                className={css.removeBtn}
                onClick={(id) => {
                  handleClick(item.id);
                }}
              >
                <CreateOutlinedIcon />
              </ListItemIcon>
              <ListItemText className={css.productName} primary={item.name}>
                {" "}
              </ListItemText>
              <ListItemText
                className={css.qty}
                primary={"€" + item.price.toLocaleString()}
              />
              <ListItemSecondaryAction>
                <ButtonGroup className={css.buttons}>
                  <Button
                    aria-label="reduce"
                    onClick={() => {
                      handleSubtractQuantity(item.id);
                    }}
                  >
                    <RemoveIcon fontSize="small" />
                  </Button>
                  <Button
                    className={css.addBtn}
                    aria-label="increase"
                    onClick={() => {
                      handleAddQuantity(item.id);
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </Button>
                </ButtonGroup>
              </ListItemSecondaryAction>
            </ListItem>
            <Collapse timeout="auto" unmountOnExit in={expanded[item.id]}>
              <ListItem>
                <form onSubmit={handleInputChange}>
                  <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    label="Add note"
                    multiline
                    name={item.id}
                    onChange={handleChange}
                    type="text"
                    rows={3}
                    placeholder="E.G .without cheese"
                    variant="outlined"
                  />
                  <Button
                    size="small"
                    variant="outlined"
                    className={css.btn}
                    color="primary"
                    type="submit"
                  >
                    Add Note
                  </Button>
                </form>
              </ListItem>
            </Collapse>
          </List>
        </div>
      );
    })
  ) : (
    <Typography align="center" paragraph={true} variant="h6">
      {t("Your basket is empty")}{" "}
    </Typography>
  );
  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message="you location is unknown"
        key={vertical + horizontal}
      />
      <div className="container">
        <div className={css.cart}>
          {props.items.total !== 0 && (
            <React.Fragment>
              <Typography paragraph={true} align="center" variant="h6">
                {t("your basket")}{" "}
              </Typography>
              <Divider variant="middle" />
            </React.Fragment>
          )}

          {addedItems}
          {props.items.total !== 0 && (
            <Fragment>
              <Divider variant="middle" />
              <List component="nav" aria-label="main mailbox folders">
                <ListItem>
                  <ListItemText
                    className={css.productName}
                    primary={t("total")}
                  >
                    {" "}
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <Typography align="center" variant="body1">
                      €{props.items.total}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>

                <ListItem>
                  <Button
                    onClick={handleOrder}
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={css.orderBtn}
                  >
                    {t("order")}
                  </Button>
                </ListItem>
              </List>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    items: state.cart,
    OrderCustomerAddress: state.order.CustomerAddress,
    order: state.order.order,
    address: state.location.location,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetLocation: () => {
      dispatch(getLocation());
    },
    removeFromCart: (id) => {
      dispatch(removeFromCart(id));
    },
    addQuantity: (id) => {
      dispatch(addQuantity(id));
    },
    subtractQuantity: (id) => {
      dispatch(subtractQuantity(id));
    },
    onGetOrderCustomerAddress: (customerAddress, restaurantID) => {
      dispatch(getOrderCustomerAddress(customerAddress, restaurantID));
    },
    onPostOrder: (data) => {
      dispatch(postOrder(data));
    },
    onUpdateNote: (id, value) => {
      dispatch(updateNote(id, value));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Cart));
