import React from "react";
import { Trans } from "react-i18next";

import css from "./HowWorks.module.scss";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";

import gif1 from "../../../../assets/img/bulding.gif";
import gif2 from "../../../../assets/img/menu.gif";
import gif3 from "../../../../assets/img/delivery.gif";

export default function HowWorks() {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={6}>
        <Grid item xs={12} sm={4}>
          <Card className={css.cardContent}>
            <CardActionArea>
              <CardMedia
                className={css.mediaImg}
                component="img"
                image={gif1}
              />
            </CardActionArea>
            <CardContent >
              <Typography className={css.title} variant="h5" align="center">
                <Trans i18nKey="box1.part1"></Trans>
              </Typography>
              <Typography variant="body1" align="center">
                <Trans i18nKey="box1.part2"></Trans>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card className={css.cardContent}>
            <CardActionArea>
              <CardMedia component="img" image={gif2} />
            </CardActionArea>
            <CardContent >
              <Typography variant="h5" className={css.title} align="center">
                {" "}
                <Trans i18nKey="box2.part1"></Trans>
              </Typography>
              <Typography variant="body1" align="center">
                {" "}
                <Trans i18nKey="box2.part2"></Trans>{" "}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card className={css.cardContent}>
            <CardActionArea>
              <CardMedia component="img" image={gif3} />
            </CardActionArea>
            <CardContent >
              <Typography variant="h5" className={css.title} align="center">
                {" "}
                <Trans i18nKey="box3.part1"></Trans>{" "}
              </Typography>
              <Typography variant="body1" align="center">
                {" "}
                <Trans i18nKey="box3.part2"></Trans>{" "}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
