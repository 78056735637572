import { Redirect } from "react-router-dom";
export const getRoutes = (roles) => {
  let routes = null;

  if (roles.some((role) => role.roleName.toLowerCase() === "admin")) {
    routes = require("../routes/dashboard");
  }

  if (roles.some((role) => role.roleName.toLowerCase() === "customer")) {
    //routes = require("../routes/landingPage");
    <Redirect to="/" />;
  }

  return routes;
};
