
export const GET_PRODUCT_CATEGORY = 'GET_PRODUCT_CATEGORY'
export const GET_PRODUCT_CATEGORY_START = 'GET_PRODUCT_CATEGORY_START'
export const GET_PRODUCT_CATEGORY_FAIL = 'GET_PRODUCT_CATEGORY_FAIL'
export const GET_PRODUCT_CATEGORY_SUCCESS = 'GET_PRODUCT_CATEGORY_SUCCESS'

export const DELETE_PRODUCT_CATEGORY = 'DELETE_PRODUCT_CATEGORY'
export const DELETE_PRODUCT_CATEGORY_START = 'DELETE_PRODUCT_CATEGORY_START'
export const DELETE_PRODUCT_CATEGORY_FAIL = 'DELETE_PRODUCT_CATEGORY_FAIL'
export const DELETE_PRODUCT_CATEGORY_SUCCESS = 'DELETE_PRODUCT_CATEGORY_SUCCESS'

export const POST_PRODUCT_CATEGORY = 'POST_PRODUCT_CATEGORY'
export const POST_PRODUCT_CATEGORY_START = 'POST_PRODUCT_CATEGORY_START'
export const POST_PRODUCT_CATEGORY_FAIL = 'POST_PRODUCT_CATEGORY_FAIL'
export const POST_PRODUCT_CATEGORY_SUCCESS = 'POST_PRODUCT_CATEGORY_SUCCESS'

export const PUT_PRODUCT_CATEGORY = 'PUT_PRODUCT_CATEGORY'
export const PUT_PRODUCT_CATEGORY_START = 'PUT_PRODUCT_CATEGORY_START'
export const PUT_PRODUCT_CATEGORY_FAIL = 'PUT_PRODUCT_CATEGORY_FAIL'
export const PUT_PRODUCT_CATEGORY_SUCCESS = 'PUT_PRODUCT_CATEGORY_SUCCESS'