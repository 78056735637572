import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import clsx from "clsx";
import Badge from "@material-ui/core/Badge";
import Menu from "@material-ui/icons/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import Select from "@material-ui/core/Select";
import Logo from "../../../assets/img/vegafoodies-3.png";
import MenuItem from "@material-ui/core/MenuItem";
import Drawer from "@material-ui/core/Drawer";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import css from "./Header.module.scss";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@material-ui/icons/Language";
import en from "../../../assets/img/en.png";
import nl from "../../../assets/img/nl.png";
const useStyles = makeStyles({
  list: {
    width: 280,
  },
});

export const Header = (props) => {
  const [state, setState] = useState({ right: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    ></div>
  );
  //////////////////
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -4,
      top: 5,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }))(Badge);

  const [currentlocale, setCurrentlocale] = useState();

  if (i18n.language !== currentlocale) {
    setCurrentlocale(i18n.language);
  }
  const handleChange = (e) => {
    i18n.changeLanguage(e.target.value);
    setCurrentlocale(e.target.value);
  };

  return (
    <div>
      <header>
        <Box display="flex">
          <Box p={1} display="flex" alignItems="center" flexGrow={1}>
            <Link to={`/`}>
              <img alt="logo" src={Logo} />
            </Link>
          </Box>
          {/* <Box p={1} display={{ xs: 'block', sm: 'none' }} >
            <MenuIcon onClick={toggleDrawer("right", true)} />
          </Box> */}
          <Box component="div">
            <Box
              p={1}
              display={{ xs: "none", sm: "none", md: "block" }}
              display="flex"
              alignItems="center"
            >
              <Link to={`/menu/${props.items.restaurantName}`}>
                <IconButton aria-label="cart">
                  <StyledBadge
                    badgeContent={props.items.addedItems.length}
                    color="secondary"
                  >
                    <LocalMallOutlinedIcon />
                  </StyledBadge>
                </IconButton>
              </Link>
              <Box className={css.localeWrapper} p={2}>
                <Select
                  defaultValue="en-US"
                  className={css.locale}
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  onChange={handleChange}
                  value={currentlocale || "EN"}
                >
                  <MenuItem value={"nl"}>NL </MenuItem>
                  <MenuItem value={"en-US"}>EN</MenuItem>
                </Select>
              </Box>

              <Link to={`/login`}>
                <PersonOutlineOutlinedIcon />
              </Link>
            </Box>
          </Box>
        </Box>
      </header>

      <Fragment>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
          <Select
            defaultValue="en-US"
            className={css.locale}
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            onChange={handleChange}
            value={currentlocale || "EN"} // TODO: fix value={"nl" || "en"}
          >
            <MenuItem value={"nl"}>NL</MenuItem>
            <MenuItem value={"en-US"}>EN</MenuItem>
          </Select>
          <IconButton aria-label="cart">
            <StyledBadge
              badgeContent={props.items.addedItems.length}
              color="secondary"
            >
              <LocalMallOutlinedIcon fontSize="large" />
            </StyledBadge>
          </IconButton>
          <a href="/login"> {t("sign up")} </a>
        </Drawer>
      </Fragment>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    items: state.cart,
  };
};

export default connect(mapStateToProps, null)(Header);
