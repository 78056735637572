import React from "react";
import {Link} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";

////crousel from https://reactjsexample.com/react-simple-infinite-carousel-with-lazy-loading-and-responsive-support/
import InfiniteCarousel from "react-leaf-carousel";
import css from "./TopFood.module.scss";

import resturanLogo from "../../../../assets/img/resturants/logo_pieperz_zw.png";


export const TopFood = (props) => {

  

  return (
    <InfiniteCarousel
      breakpoints={[
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ]}
      dots={false}
      showSides={true}
      sidesOpacity={1}
      sideSize={0.1}
      slidesToScroll={1}
      slidesToShow={5}
      scrollOnDevice={true}
    >
        {props.items.map((itm) => (
      <div className={css.wrapper}>
        <Card className={css.card}>
          <CardActionArea>
            <CardMedia
              className={css.media}
              component="img"
              image={itm.image}
            />
          </CardActionArea>
          <Link to= {`menu/${itm.restaurantName}`}>
       
          <CardContent>
            <Typography variant="h5" align="center" className={css.dishTitle}>
                {itm.name}
            </Typography>
            <Typography variant="h6" align="center" className={css.dishPrice}>
              {itm.price} €
            </Typography>
          </CardContent>
          </Link>
        </Card>
      </div>
        ))}
    
    
     
    
     
    </InfiniteCarousel>
  );
}
export default TopFood;