export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCTS_START = 'GET_PRODUCTS_START'
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'

export const GET_PRODUCT = 'GET_PRODUCT'
export const GET_PRODUCT_START = 'GET_PRODUCT_START'
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL'
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS'


export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const DELETE_PRODUCT_START = 'DELETE_PRODUCT_START'
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'

export const POST_PRODUCT = 'POST_PRODUCT'
export const POST_PRODUCT_START = 'POST_PRODUCT_START'
export const POST_PRODUCT_FAIL = 'POST_PRODUCT_FAIL'
export const POST_PRODUCT_SUCCESS = 'POST_PRODUCT_SUCCESS'

export const PUT_PRODUCT = 'PUT_PRODUCT'
export const PUT_PRODUCT_START = 'PUT_PRODUCT_START'
export const PUT_PRODUCT_FAIL = 'PUT_PRODUCT_FAIL'
export const PUT_PRODUCT_SUCCESS = 'PUT_PRODUCT_SUCCESS'

