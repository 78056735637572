import * as actionTypes from './actionTypes'
import axios from '../../../axios/localhost'

export const postRestaurants = (data) => {
  
    return dispatch => {
        dispatch(postRestaurantsStart())
        let name = data.name;
        axios.post(`/restaurant/${name}`, { name: name, ...data })
            .then(response => dispatch(postRestaurantsSuccess(data)))
            .catch(error => {
                dispatch(postRestaurantsFail(error.response.data.message))
            })
    }
}

export const postRestaurantsStart = () => {
    return {
        type: actionTypes.POST_RESTAURANTS_START
    }
}

export const postRestaurantsFail = (error) => {
    return {
        type: actionTypes.POST_RESTAURANTS_FAIL,
        error: error
    }
}

export const postRestaurantsSuccess = (data) => {
    return {
        type: actionTypes.POST_RESTAURANTS_SUCCESS,
        data: data
    }
}
