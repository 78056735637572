import React from "react";
import * as actions from "../../../../store/actions/auth";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
// Redux Form
const renderTextField = ({
  input,
  label,
  meta: { touched, invalid, error, warning },
  ...custom
}) => (
  <TextField
    variant="outlined"
    margin="normal"
    fullWidth
    size="small"
    autoComplete="off"
    id={label}
    label={label}
    name={label}
    {...input}
    {...custom}
  />
);

let SignIn = (props) => {
  const { handleSubmit } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  let error = null;

  if (props.errorMsg) {
    error = <Alert severity="error">{props.errorMsg}</Alert>;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        {error}
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("sign in")}
        </Typography>

        <form onSubmit={handleSubmit}>
          <Field
            label="Email"
            name="email"
            type="email"
            component={renderTextField}
          />
          <Field
            label="password"
            name="password"
            type="password"
            component={renderTextField}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label={t("Remember me")}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {props.loading === false ? (
              <div>{t("sign in")}</div>
            ) : (
              <CircularProgress color="#fff" size={30} disableShrink />
            )}
          </Button>
        </form>
      </div>
    </Container>
  );
};

SignIn = reduxForm({
  form: "signin",
})(SignIn);

const mapStateToProps = (state) => {
  return {
    errorMsg: state.auth.error,
    loading: state.auth.loading,
    registrationSuccessful: state.auth.registrationSuccessful,
    registrationMessage: state.auth.registrationMessage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (values) => dispatch(actions.login(values)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIn));
